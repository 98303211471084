import { Dropdown, Menu } from "antd";
import styles from "./LangChooser.less";
import i18n from "@shared/i18next/config";
import useLanguage from "@shared/hooks/useLanguage";

export default function LangChooser() {
	const { changeLanguage } = useLanguage();

	const chooseLanguage = (e: { key: string }) => {
		changeLanguage(e.key);
	};

	return (
		<div className={styles["lang"]}>
			<Dropdown
				overlay={<Menu items={languageList} onClick={chooseLanguage} />}
				overlayClassName={styles.languageDrop}
				placement="bottomRight"
			>
				{i18n.language === "en" ?
					<div className={styles["en"]}></div>
					: i18n.language === "zh_CN" ?
					<div className={styles["zh_CN"]}></div> : <div className={styles["zh_HK"]}></div>
				}
			</Dropdown>
		</div>
	);
}

const languageList = [
	{
		label: "English",
		key: "en",
	},
	{
		label: "简体中文",
		key: "zh_CN",
	},
	{
		label: "繁體中文",
		key: "zh_HK",
	},
];