const { REACT_APP_ENV_BASE_URL } = process.env;

const baseUrl = REACT_APP_ENV_BASE_URL;

export const urls = {
	base: baseUrl,
	fundamental: baseUrl + "fundamental",
	app: baseUrl + "cis/app",
	finance: baseUrl + "cis/finance",
	fund: baseUrl + "cis/fund",
	settlement: baseUrl + "cis/settlement",
	trading: baseUrl + "cis/trading",
	quantFinance: baseUrl + "quant-finance",
	oracle: baseUrl + "oracle",
};
