import { getLocalStorage, removeLocalStorage, setLocalStorage } from "@shared/utils/localStorageManager";

export default class CountdownProgressManager {
	countdownType: string;
	maxSeconds: number;


	constructor (countdownType: string, maxSeconds: number) {
		this.countdownType = countdownType;
		this.maxSeconds = maxSeconds;
	}

	getRemainTime(): number {
		const now = Date.now();
		const start = Number(getLocalStorage(this.countdownLocalStorageName()));
		const leftTime = Math.ceil(this.maxSeconds - ((now - start) / 1000));

		if (leftTime === undefined || leftTime < 0) {
			return this.maxSeconds;
		} else {
			return leftTime;
		}
	}

	//待机
	isReady(): boolean {
		return this.getRemainTime() === this.maxSeconds;
	}

	//运行
	isRunning(): boolean {
		return this.getRemainTime() > 0;
	}

	resetStartTime(): void {
		setLocalStorage(this.countdownLocalStorageName(), Date.now());
	}

	clearRemainTime(): void {
		removeLocalStorage(this.countdownLocalStorageName());
	}

	countdownLocalStorageName(): string {
		return this.countdownType + "CountdownStartTime";
	}
}