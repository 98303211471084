import React from "react";
import styles from "./Myfund.less";
import MyfundHeader from "./MyfundHeader";
import MyfundTable from "./MyfundTable";

export default function Myfund() {
	return (
		<div className={styles["myfund-container"]}>
			<MyfundHeader />
			<MyfundTable />
		</div>
	);
}
