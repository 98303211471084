import React from "react";
import styles from "./CreateFund.less";
import StepsBar from "./StepsBar";
import NavigateHeader from "./NavigateHeader";

export default function CreateFund() {
	return (
		<div className={styles["create-container"]}>
			<NavigateHeader />
			<StepsBar />
		</div>
	);
}
