import React from "react";
import "./App.css";
import { HashRouter, Navigate } from "react-router-dom";
import { Routes, Route } from "react-router";
import { ConfigProvider } from "antd";
import Layout from "@shared/components/Layout/Layout";
import CreateFund from "@/manager/components/createFund/CreateFund";
import useLanguage from "@shared/hooks/useLanguage";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";
import "moment/locale/en-gb";
import zh_CN from "antd/es/locale/zh_CN";
import zh_HK from "antd/es/locale/zh_HK";
import en from "antd/es/locale/en_GB";
import Login from "src/main/login/components/Login/Login";
import Myfund from "@/manager/components/Myfund/Myfund";
import { isSignedIn } from "@shared/utils/userInfoUtils";
import AuthRouter from "@shared/components/AuthRouter/AuthRouter";

function App() {
	const [languageConfig, setLanguageConfig] = React.useState(en);
	const { initLang, currentLang } = useLanguage();

	React.useEffect(() => {
		switch (currentLang) {
			case "zh_CN":
				moment.locale("zh-cn");
				setLanguageConfig(zh_CN);
				break;
			case "zh_HK":
				moment.locale("zh-hk");
				setLanguageConfig(zh_HK);
				break;
			case "en":
				moment.locale("en-gb");
				setLanguageConfig(en);
				break;
			default:
				break;
		}
	}, [currentLang]);

	React.useEffect(() => {
		initLang();
	}, [initLang]);

	return (
		<ConfigProvider prefixCls="ac" locale={languageConfig}>
			<HashRouter>
				<Routes>
					<Route
						path="/"
						element={<Navigate to={isSignedIn() ? "fundManagement/myfund" : "/login"} replace />}
					/>
					<Route path="/*" element={<Layout />}>
						<Route
							path="fundManagement/*"
							element={
								<Routes>
									<Route
										path="myfund"
										element={
											<AuthRouter>
												<Myfund />
											</AuthRouter>
										}
									></Route>
									<Route
										path="create-fund"
										element={
											<AuthRouter>
												<CreateFund />
											</AuthRouter>
										}
									></Route>
									<Route path="/*" element={<Navigate to="myfund" />}></Route>
								</Routes>
							}
						></Route>
					</Route>
					<Route path="/login" element={<Login />}></Route>
				</Routes>
			</HashRouter>
		</ConfigProvider>
	);
}

export default App;
