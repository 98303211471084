import React from "react";
import styles from "./ImportStrategyInvestmentReport.less";
import ImportStrategy from "./ImportStrategy";
import ImportInvestmentReport from "./ImportInvestmentReport";
import { Key } from "antd/es/table/interface";
import { ReferenceInvestmentReport } from "@/manager/typings/importInvestmentReport";

interface Props {
	referenceStrategies: Key[];
	setReferenceStrategies: (val: Key[]) => void;
	fundAccount: string;
	setReferenceInvestmentReport: (val: ReferenceInvestmentReport) => void;
	setHasReferenceStrategies: (val: boolean) => void;
	setHasReferenceInvestmentReport: (val: boolean) => void;
	validate: boolean;
	setValidate: (val: boolean) => void;
	setReferenceStrategiesInit: (val: boolean) => void;
	setReferenceInvReportInit: (val: boolean) => void;
	submit: boolean;
	setSubmit: (val: boolean) => void;
}
export default function ImportStrategyInvestmentReport({
	referenceStrategies,
	setReferenceStrategies,
	fundAccount,
	setReferenceInvestmentReport,
	setHasReferenceStrategies,
	setHasReferenceInvestmentReport,
	validate,
	setValidate,
	setReferenceStrategiesInit,
	setReferenceInvReportInit,
	submit,
	setSubmit,
}: Props) {
	return (
		<div className={styles["import-container"]}>
			<ImportStrategy
				setReferenceStrategies={setReferenceStrategies}
				setReferenceStrategiesInit={setReferenceStrategiesInit}
			/>
			<ImportInvestmentReport
				referenceStrategies={referenceStrategies}
				fundAccount={fundAccount}
				setReferenceInvestmentReport={setReferenceInvestmentReport}
				setHasReferenceStrategies={setHasReferenceStrategies}
				setHasReferenceInvestmentReport={setHasReferenceInvestmentReport}
				validate={validate}
				setValidate={setValidate}
				setReferenceInvReportInit={setReferenceInvReportInit}
				submit={submit}
				setSubmit={setSubmit}
			/>
		</div>
	);
}
