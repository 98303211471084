import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isSignedIn, isLoginExpired } from "@shared/utils/userInfoUtils";
import { clearLocalStorage } from "@/login/model/authService";

interface Props {
	children: ReactNode;
}

export default function AuthRouter(props: Props) {
	const navigate = useNavigate();

	useEffect(() => {
		if (!isSignedIn() || isLoginExpired()) {
			clearLocalStorage();
			navigate("/login");
		}
	}, [navigate]);

	return <>{props.children}</>;
}