import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MyfundHeader.less";
import useLanguage from "@shared/hooks/useLanguage";
import sliceA from "@shared/assets/png/Slice3.png";
import sliceB from "@shared/assets/png/Slice1.png";
import sliceC from "@shared/assets/png/Slice2.png";
import { useGetSummary } from "@/manager/hooks/myFund";
import { formatAssetsNum } from "@shared/utils/numberUtils";

export default function MyfundHeader() {
	const { t, keys } = useLanguage();
	const navigate = useNavigate();
	const {
		summary: { settledShare, unsettledShare, safetyNetAmount },
	} = useGetSummary();

	return (
		<div className={styles["myfund-header-container"]}>
			<div className={styles["header-title"]}>
				<div>{t(keys.myFunds)}</div>
				<div
					onClick={() => {
						navigate("/fundManagement/create-fund");
					}}
				>
					{t(keys.newFund)}
				</div>
			</div>
			<div className={styles["header-cards"]}>
				<div className={styles["card"]}>
					<div>
						<img src={sliceA} />
					</div>
					<div>
						<div>{t(keys.settledSharedProfit)}</div>
						<div>
							{formatAssetsNum(settledShare || 0, 2)} <span>USDT</span>
						</div>
					</div>
				</div>
				<div className={styles["card"]}>
					<div>
						<img src={sliceB} />
					</div>
					<div>
						<div>{t(keys.unsettledSharedProfit)}</div>
						<div>
							{formatAssetsNum(unsettledShare || 0, 2)} <span>USDT</span>
						</div>
					</div>
				</div>
				<div className={styles["card"]}>
					<div>
						<img src={sliceC} />
					</div>
					<div>
						<div>{t(keys.frozenSafetyNet)}</div>
						<div>
							{formatAssetsNum(safetyNetAmount || 0, 2)} <span>USDT</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
