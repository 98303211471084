import React from "react";
import styles from "./InvestmentReportSummary.less";
import { ReportSummary } from "@/manager/typings/importInvestmentReport";
import { formatPercentageNum } from "@shared/utils/numberUtils";
import useLanguage from "@shared/hooks/useLanguage";

interface Props {
	reportSummary: ReportSummary | undefined;
}
export default function InvestmentReportSummary({ reportSummary }: Props) {
	const { t, keys } = useLanguage();

	return (
		<div className={styles["performance-container"]}>
			<div className={styles["performance-item"]}>
				<div className={styles["item-text"]}>{t(keys.apr)}</div>
				<div className={styles["item-value"]}>
					{reportSummary ? formatPercentageNum(reportSummary.annualizedReturn) : "--"}
				</div>
			</div>
			<div className={styles["performance-item"]}>
				<div className={styles["item-text"]}>{t(keys.tradeTimes)}</div>
				<div className={styles["item-value"]}>{reportSummary?.tradeCount ?? "--"}</div>
			</div>
			<div className={styles["performance-item"]}>
				<div className={styles["item-text"]}>{t(keys.winRatio)}</div>
				<div className={styles["item-value"]}>
					{reportSummary ? formatPercentageNum(reportSummary.winRate) : "--"}
				</div>
			</div>
			<div className={styles["performance-item"]}>
				<div className={styles["item-text"]}>{t(keys.cumulativeReturn)}</div>
				<div className={styles["item-value"]}>
					{reportSummary ? formatPercentageNum(reportSummary.cumulativeReturn) : "--"}
				</div>
			</div>
		</div>
	);
}
