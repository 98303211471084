import axios from "axios";
import { Response } from "@shared/utils/request";
import { LoginBody } from "@/login/model/login";

export async function postRequest(
	baseURL: string,
	path: string,
	params: Record<string, any> | undefined
) {
	try {
		const data = await axios.post<Response<LoginBody>>(path, params, {
			headers:{ "Accept-Version": "v1" },
			baseURL,
		});
		return data;
	} catch (error) {
		console.error("post token error:", error);
		return null;
	}
}
