
import { urls } from "@shared/config/envconfig";
import { removeLocalStorage, setLocalStorage } from "@shared/utils/localStorageManager";
import { postRequest } from "@shared/utils/postRequests";

export async function tryLogin(mail: string, password: string, mac: string) {
	return postRequest(urls.fundamental, "/public/auth/loginCis", {
		mail: mail,
		password: password,
		mac: mac,
		deviceType: "WEB"
	});
}

export const saveToLocalStorage = (params: {
	mail: string;
	accessToken: string;
	refreshToken: string;
	features: string[];
}) => {
	setLocalStorage("userInfo", params);
};

export const clearLocalStorage = () => {
	removeLocalStorage("userInfo");
};