import { ValidateStatus } from "antd/es/form/FormItem";
import { Key } from "react";

export const investmentReportFormValidate = (
	startValidateStatus: ValidateStatus,
	endValidateStatus: ValidateStatus
) => {
	return startValidateStatus === "success" && endValidateStatus === "success";
};

export enum SubmitValidateResult {
	Success,
	Lack,
	Error,
}
export const submitValidate = (
	fundAccount: string,
	start: number,
	end: number,
	referenceStrategies: Key[],
	setValidate: (val: boolean) => void,
	startValidateStatus: ValidateStatus,
	endValidateStatus: ValidateStatus
) => {
	const hasReferenceStrategies = referenceStrategies.length > 0;
	const hasReferenceInvestmentReport = fundAccount && start && end;

	if (
		(hasReferenceStrategies && !hasReferenceInvestmentReport) ||
		investmentReportFormValidate(startValidateStatus, endValidateStatus)
	) {
		setValidate(true);
		return SubmitValidateResult.Success;
	} else if (!hasReferenceStrategies && !hasReferenceInvestmentReport) {
		return SubmitValidateResult.Lack;
	} else {
		return SubmitValidateResult.Error;
	}
};
