import axios, { AxiosRequestConfig, Method } from "axios";
import axiosRetry from "axios-retry";
import qs from "qs";
import { getAccessToken } from "./userInfoUtils";
import { urls } from "@shared/config/envconfig";

export interface Response<T> {
	code: string;
	body: T;
	message: string;
}

export enum ResponseResult {
	Success,
	Fail,
}

export interface Fn {
	(result: ResponseResult): void;
}

export interface RequestOptions {
	method: Method;
	baseURL: string;
	path: string;
	dataOrParams: Record<string, any>;
	accessToken?: string;
	fn?: Fn;
}

function createAxiosInstance() {
	const instance = axios.create();
	axiosRetry(instance, {
		retries: 2,
		retryDelay: (retryCount: number) => {
			return retryCount * 1000;
		},
		shouldResetTimeout: true,
	});

	return instance;
}

async function httpRequest<Result>(options: RequestOptions) {
	const instance = createAxiosInstance();

	const { method, baseURL, path, dataOrParams, accessToken, fn } = options;

	const headers: Record<string, string> = { "Accept-Version": "v1" };
	if (accessToken) {
		headers.accessToken = accessToken;
	}
	const config = {
		baseURL,
		headers,
	} as AxiosRequestConfig;

	if (method === "GET") {
		config.params = dataOrParams;
		config.paramsSerializer = (params) => qs.stringify(params).replaceAll(/%5B\d+%5D/g, "");
	} else if (method === "POST") {
		config.data = dataOrParams;
	} else {
		throw new Error(`Unsupported HTTP method: ${method}`);
	}

	try {
		const response = await instance.request<Response<Result>>({
			method,
			url: path,
			...config,
		});

		if (response.data?.code === "OK") {
			fn && fn(ResponseResult.Success);
			return response.data.body;
		} else {
			fn && fn(ResponseResult.Fail);
			return response.data;
		}
	} catch (error) {
		throw error;
	}
}

function createRequestFunc(method: Method, baseURL: string) {
	return async function (path: string, dataOrParams: Record<string, any>, fn?: (result: ResponseResult) => void) {
		const accessToken = getAccessToken();
		const options = { method, baseURL, path, dataOrParams, accessToken, fn };
		return httpRequest(options);
	};
}

const getFundamentalRequest = createRequestFunc("GET", urls.fundamental);
const postFundamentalRequest = createRequestFunc("POST", urls.fundamental);

const getAppRequest = createRequestFunc("GET", urls.app);
const postAppRequest = createRequestFunc("POST", urls.app);

const getFinanceRequest = createRequestFunc("GET", urls.finance);
const postFinanceRequest = createRequestFunc("POST", urls.finance);

const getQuantFinanceRequest = createRequestFunc("GET", urls.quantFinance);
const postQuantFinanceRequest = createRequestFunc("POST", urls.quantFinance);

const getFundRequest = createRequestFunc("GET", urls.fund);
const postFundRequest = createRequestFunc("POST", urls.fund);

const getSettlementRequest = createRequestFunc("GET", urls.settlement);
const postSettlementRequest = createRequestFunc("POST", urls.settlement);

const getTradingRequest = createRequestFunc("GET", urls.trading);
const postTradingRequest = createRequestFunc("POST", urls.trading);

const getOracleRequest = createRequestFunc("GET", urls.oracle);

export {
	httpRequest,
	getFundamentalRequest,
	postFundamentalRequest,
	getAppRequest,
	postAppRequest,
	getFinanceRequest,
	postFinanceRequest,
	getQuantFinanceRequest,
	postQuantFinanceRequest,
	getFundRequest,
	postFundRequest,
	getSettlementRequest,
	postSettlementRequest,
	getTradingRequest,
	postTradingRequest,
	getOracleRequest,
};
