import styles from "./DetailTable.less";
import useLanguage from "@shared/hooks/useLanguage";
import { TranslationKeys } from "@shared/i18next/languages/keys";
import { Table } from "antd";
import { TFunction } from "i18next";

interface Props {
	isSettled: boolean;
}

export default function DetailTable(props: Props) {

	const { t, keys } = useLanguage();

	const profit = [
		{
			"time": 111,
			"dateRange": 222,
			"investorProfit": 333,
			"cisSharedProfit": 444,
			"managerSharedProfit": 555,
		},
		{
			"time": 111,
			"dateRange": 222,
			"investorProfit": 333,
			"cisSharedProfit": 444,
			"managerSharedProfit": 555,
		},
		{
			"time": 111,
			"dateRange": 222,
			"investorProfit": 333,
			"cisSharedProfit": 444,
			"managerSharedProfit": 555,
		},
		{
			"time": 111,
			"dateRange": 222,
			"investorProfit": 333,
			"cisSharedProfit": 444,
			"managerSharedProfit": 555,
		},
		{
			"time": 111,
			"dateRange": 222,
			"investorProfit": 333,
			"cisSharedProfit": 444,
			"managerSharedProfit": 555,
		},
	];

	return (
		<div className={styles["detailTable"]}>
			<Table
				columns={props.isSettled ? getSettledColumns(t, keys) : getUnsettledColumns(t, keys)}
				dataSource={profit}
			/>
		</div>
	);
}

const getSettledColumns = (t: TFunction<"translation", undefined>, keys: TranslationKeys) => {
	return [
		{
			title: t(keys.settleTime),
			dataIndex: 'time',
			key: "time",
			width: 160,
		},
		{
			title: t(keys.dateRange),
			dataIndex: 'dateRange',
			key: "dateRange",
			width: 200,
		},
		{
			title: t(keys.investorProfits) + " USDT",
			dataIndex: 'investorProfit',
			key: "investorProfit",
			width: 122,
		},
		{
			title: t(keys.cisSharedProfit) + " USDT",
			dataIndex: 'cisSharedProfit',
			key: "cisSharedProfit",
			width: 143,
		},
		{
			title: t(keys.managerSharedProfit) + " USDT",
			dataIndex: 'managerSharedProfit',
			key: "managerSharedProfit",
			width: 177,
		},
	];
};

const getUnsettledColumns = (t: TFunction<"translation", undefined>, keys: TranslationKeys) => {
	return [
		{
			title: t(keys.settleTime),
			dataIndex: 'time',
			key: "time",
			width: 160,
		},
		{
			title: t(keys.dateRange),
			dataIndex: 'dateRange',
			key: "dateRange",
			width: 200,
		},
		{
			title: "Investor Expected Profit" + " USDT",
			dataIndex: 'investorProfit',
			key: "investorProfit",
			width: 122,
		},
		{
			title: t(keys.cisExpectedSharedProfit) + " USDT",
			dataIndex: 'cisSharedProfit',
			key: "cisSharedProfit",
			width: 143,
		},
		{
			title: t(keys.managerExpectedSharedProfit) + " USDT",
			dataIndex: 'managerSharedProfit',
			key: "managerSharedProfit",
			width: 177,
		},
	];
};