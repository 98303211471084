import useLanguage from "@shared/hooks/useLanguage";
import styles from "./UserInfo.less";
import { Button, Popover } from "antd";
import { useLogout } from "@shared/hooks/logoutHooks";
import { getUserInfo } from "@shared/utils/userInfoUtils";

export default function UserInfo() {

	return (
		<div className={styles["userInfo"]}>
			<Popover
				trigger="hover"
				arrow={false}
				content={PopoverContent()}
				overlayClassName={styles["popover"]}
			>
				<div className={styles["user"]}></div>
			</Popover>
		</div>
	);
}

const PopoverContent = () => {

	const { t, keys } = useLanguage();
	const { handleLogoutClick } = useLogout();
	const { mail } = getUserInfo();

	return (
		<>
			<div className={styles["userName"]}>
				<div className={styles["userName_icon"]}></div>
				<div className={styles["userName_text"]}>{mail}</div>
			</div>
			<div className={styles["balance_title"]}>
				<div>{t(keys.balance)}</div>
				<div>(USDT)</div>
				<div>:</div>
			</div>
			<div className={styles["balance_number"]}>
				<div>35000.00</div>
			</div>
			<div className={styles["balance_manage"]}>
				<Button className={styles["deposit"]}>
					{t(keys.deposit)}
				</Button>
				<Button className={styles["withdraw"]}>
					{t(keys.withdraw)}
				</Button>
			</div>
			<div className={styles["account"]}>
				{t(keys.exchangeAccount)}
			</div>
			<div
				className={styles["logOut"]}
				onClick={handleLogoutClick}
			>
				{t(keys.logOut)}
			</div>
		</>
	);
};