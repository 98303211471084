import React from "react";
import styles from "./ImportInvestmentReport.less";
import ImportInvestmentReportForm from "./ImportInvestmentReportForm";
import InvestmentReportSummary from "./InvestmentReportSummary";
import AccumulativeChart from "./AccumulativeChart";
import useLanguage from "@shared/hooks/useLanguage";
import { ReferenceInvestmentReport, ReportSummary } from "@/manager/typings/importInvestmentReport";
import { Key } from "antd/es/table/interface";

interface Props {
	referenceStrategies: Key[];
	fundAccount: string;
	setReferenceInvestmentReport: (val: ReferenceInvestmentReport) => void;
	setHasReferenceStrategies: (val: boolean) => void;
	setHasReferenceInvestmentReport: (val: boolean) => void;
	validate: boolean;
	setValidate: (val: boolean) => void;
	setReferenceInvReportInit: (val: boolean) => void;
	submit: boolean;
	setSubmit: (val: boolean) => void;
}

export default function ImportInvestmentReport({
	referenceStrategies,
	fundAccount,
	submit,
	setSubmit,
	validate,
	setValidate,
	setReferenceInvReportInit,
	setReferenceInvestmentReport,
	setHasReferenceStrategies,
	setHasReferenceInvestmentReport,
}: Props) {
	const { t, keys } = useLanguage();
	const [invReportSummary, setInvReportSummary] = React.useState<ReportSummary | undefined>();
	const [periodAccumulativeReturn, setPeriodAccumulativeReturn] = React.useState<(string | number)[][]>([]);

	return (
		<div className={styles["report-container"]}>
			<div className={styles["report-header"]}>{t(keys.referenceReport)}</div>
			<div className={styles["report-content"]}>
				<ImportInvestmentReportForm
					referenceStrategies={referenceStrategies}
					setHasReferenceStrategies={setHasReferenceStrategies}
					fundAccount={fundAccount}
					setHasReferenceInvestmentReport={setHasReferenceInvestmentReport}
					submit={submit}
					setSubmit={setSubmit}
					validate={validate}
					setValidate={setValidate}
					setReferenceInvReportInit={setReferenceInvReportInit}
					invReportSummary={invReportSummary}
					setInvReportSummary={setInvReportSummary}
					setPeriodAccumulativeReturn={setPeriodAccumulativeReturn}
					setReferenceInvestmentReport={setReferenceInvestmentReport}
				/>
				<InvestmentReportSummary reportSummary={invReportSummary} />
				<AccumulativeChart periodAccumulativeReturn={periodAccumulativeReturn} />
			</div>
		</div>
	);
}
