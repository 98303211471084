/**
 * 用于资产数字的格式化，eg: 126560 => 126,560.00
 * @param num 资产数字
 * @returns
 */
export function formatAssetsNum(num: number, fractionDigits = 2) {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "decimal",
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
	return formatter.format(num);
}

export function formatPercentageNum(number: number) {
	return `${(number * 100).toFixed(2)}%`;
}

export function fixedDecimals(number: number, decimals: number = 2) {
	return parseFloat(number.toFixed(decimals));
}

export function amountConverter(amount: number) {
	if (amount < 1000) {
		return String(amount);
	} else if (amount < 1000000) {
		return String(Math.floor(amount / 1000)) + "k";
	} else if (amount < 1000000000) {
		return String(Math.floor(amount / 1000000)) + "M";
	} else {
		return String(Math.floor(amount / 1000000000)) + "B";
	}
}
