import { getLocalStorage } from "./localStorageManager";

interface CodedToken {
	clientId: string;
	exp: number;
	mac: string;
}

export interface UserInfo {
	mail: string;
	accessToken: string;
	refreshToken: string;
	features: string[];
}

export const getUserInfo = (): UserInfo => {
	const str = getLocalStorage("userInfo");
	return str ? JSON.parse(str) : "";
};

export const getAccessToken = () => {
	const { accessToken } = getUserInfo();
	return accessToken;
};

export const isSignedIn = (): boolean => {
	const accessToken = getAccessToken();
	return accessToken !== undefined;
};

export const getUserMail = () => {
	const { mail } = getUserInfo();
	return mail;
};

function getExpiredTime(token: string) {
	const codedToken: CodedToken = JSON.parse(window.atob(token.replace("_", "/").replace("-", "+").split(".")[1]));
	return codedToken.exp;
}

export function isLoginExpired() {
	const token = getAccessToken();
	const expiredTime = getExpiredTime(token) * 1000;
	const now = Date.now();
	const isExpired = now - expiredTime >= 0;

	return isExpired;
}
