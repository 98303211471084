import { StatusType } from "@shared/enum/enum";

export enum FollowType {
	Proportional = "PROPORTIONAL", //完全跟随比例
	NonProportion = "NON_PROPORTION", //自定义比例
}

export enum SettlementPeriodType {
	Month = "MONTH",
	Quarter = "QUARTER",
	HalfYear = "HALF_YEAR",
	Year = "YEAR",
}

export interface BaseInformation {
	name: string;
	startDate: number;
	endDate: number;
	settlementPeriod: SettlementPeriodType;
	fundAccount: string;
	followType: FollowType;
	isNameExist: boolean;
}

export interface BenefitAndRiskFactorsParam {
	expectedAnnualizedReturn: number;
	expectedMaximumWithdrawalRate: number;
	stopLossRatio: number;
	safetyNetAmount: number;
}

export interface LeverageRanges {
	key: string;
	pair: string;
	min: string;
	max: string;
	status: StatusType;
}

export interface RangeOfTradingTargetsParam {
	products: string;
	leverageRanges: LeverageRanges[];
	spots: string[];
	contracts: string[];
}

export interface FundFactorParams {
	baseInformation: BaseInformation;
	benefitAndRiskFactors: BenefitAndRiskFactorsParam;
	rangeOfTradingTargets: RangeOfTradingTargetsParam;
}

export const initialFundFactorParams: FundFactorParams = {
	baseInformation: {
		name: "",
		startDate: 0,
		endDate: 0,
		settlementPeriod: SettlementPeriodType.Month,
		fundAccount: "",
		followType: FollowType.Proportional,
		isNameExist: false,
	},
	benefitAndRiskFactors: {
		expectedAnnualizedReturn: 0,
		expectedMaximumWithdrawalRate: 0.01,
		stopLossRatio: 0.01,
		safetyNetAmount: 0,
	},
	rangeOfTradingTargets: {
		products: "",
		leverageRanges: [
			{
				key: "1",
				max: "",
				min: "",
				pair: "",
				status: StatusType.Normal,
			},
		],
		spots: ["1"],
		contracts: ["205"],
	},
};
