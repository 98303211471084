import React from "react";
import styles from "./BenefitAndRiskFactors.less";
import useLanguage from "@shared/hooks/useLanguage";
import { Button, Input } from "antd";
import { BenefitAndRiskFactorsParam } from "@/manager/typings/fundFactor";
import { StatusType } from "@shared/enum/enum";
import {
	checkExpectedAnnualizedReturn,
	checkExpectedMaximumWithdrawalRate,
	checkSafetyNetAmount,
	checkStopLossRatio,
	formatInputNumber,
} from "@/manager/service/createFund";
import { getFinanceRequest } from "@shared/utils/request";

interface BenefitAndRiskFactorsParams {
	data: BenefitAndRiskFactorsParam;
	changeData: React.Dispatch<React.SetStateAction<BenefitAndRiskFactorsParam>>;
	needCheckForm: boolean;
}

interface Balance {
	availableBalance: number;
	frozenMargin: number;
	walletBalance: number;
}

export const BenefitAndRiskFactors = (props: BenefitAndRiskFactorsParams) => {
	const { t, keys } = useLanguage();
	const { data, changeData, needCheckForm } = props;
	const [expectedApr, setExpectedApr] = React.useState(
		data.expectedAnnualizedReturn === 0 ? "" : String(data.expectedAnnualizedReturn * 100)
	);
	const [expectedAprStatus, setExpectedAprStatus] = React.useState<StatusType>(StatusType.Normal);
	const [estimatedMaxDrawdown, setEstimatedMaxDrawdown] = React.useState(
		data.expectedMaximumWithdrawalRate === 0.01 ? "" : String(data.expectedMaximumWithdrawalRate * 100)
	);
	const [estimatedMaxDrawdownStatus, setEstimatedMaxDrawdownStatus] = React.useState<StatusType>(StatusType.Normal);
	const [stopLossLine, setStopLossLine] = React.useState(
		data.stopLossRatio === 0.01 ? "" : String(data.stopLossRatio * 100)
	);
	const [stopLossLineStatus, setStopLossLineStatus] = React.useState<StatusType>(StatusType.Normal);
	const [safetyNetAmount, setSafetyNetAmount] = React.useState(
		data.safetyNetAmount === 0 ? "" : String(data.safetyNetAmount)
	);
	const [safetyNetAmountStatus, setSafetyNetAmountStatus] = React.useState<StatusType>(StatusType.Normal);
	const [availableBalance, setAvailableBalance] = React.useState<number>(0);

	const onFactorsChangeEvent = (
		e: React.ChangeEvent<HTMLInputElement>,
		setData: React.Dispatch<React.SetStateAction<string>>,
		setStatus: React.Dispatch<React.SetStateAction<StatusType>>
	) => {
		const value = e.target.value;
		const formatValue = formatInputNumber(value);

		switch (setData) {
			case setExpectedApr:
				if (
					formatValue === "" ||
					formatValue === "0" ||
					formatValue[formatValue.length - 1] === "." ||
					!checkExpectedAnnualizedReturn(Number(formatValue))
				) {
					setData(formatValue);
					setStatus(StatusType.Error);
				} else {
					setData(formatValue);
					setStatus(StatusType.Normal);
				}
				break;
			case setEstimatedMaxDrawdown:
				if (
					formatValue === "" ||
					formatValue[formatValue.length - 1] === "." ||
					!checkExpectedMaximumWithdrawalRate(Number(formatValue))
				) {
					setData(formatValue);
					setStatus(StatusType.Error);
				} else {
					setData(formatValue);
					setStatus(StatusType.Normal);
				}
				break;
			case setStopLossLine:
				if (
					formatValue === "" ||
					formatValue[formatValue.length - 1] === "." ||
					!checkStopLossRatio(Number(formatValue))
				) {
					setData(formatValue);
					setStatus(StatusType.Error);
				} else {
					setData(formatValue);
					setStatus(StatusType.Normal);
				}
				break;
			case setSafetyNetAmount:
				if (formatValue === "") {
					setData(formatValue);
					setStatus(StatusType.Normal);
				} else if (!checkSafetyNetAmount(Number(formatValue))) {
					setData(formatValue);
					setStatus(StatusType.Error);
				} else {
					setData(formatValue);
					setStatus(StatusType.Normal);
				}
		}
	};

	React.useEffect(() => {
		if (needCheckForm === true) {
			checkExpectedAnnualizedReturn(Number(expectedApr))
				? setExpectedAprStatus(StatusType.Normal)
				: setExpectedAprStatus(StatusType.Error);
			checkExpectedMaximumWithdrawalRate(estimatedMaxDrawdown === "" ? 1 : Number(estimatedMaxDrawdown))
				? setEstimatedMaxDrawdownStatus(StatusType.Normal)
				: setEstimatedMaxDrawdownStatus(StatusType.Error);
			checkStopLossRatio(stopLossLine === "" ? 1 : Number(stopLossLine))
				? setStopLossLineStatus(StatusType.Normal)
				: setStopLossLineStatus(StatusType.Error);
			checkSafetyNetAmount(Number(safetyNetAmount))
				? setSafetyNetAmountStatus(StatusType.Normal)
				: setSafetyNetAmountStatus(StatusType.Error);
		}
	}, [needCheckForm, expectedApr, estimatedMaxDrawdown, stopLossLine, safetyNetAmount]);

	React.useEffect(() => {
		changeData({
			expectedAnnualizedReturn: Number(expectedApr) / 100,
			expectedMaximumWithdrawalRate: Number(estimatedMaxDrawdown) / 100,
			stopLossRatio: Number(stopLossLine) / 100,
			safetyNetAmount: Number(safetyNetAmount),
		});
	}, [expectedApr, estimatedMaxDrawdown, stopLossLine, safetyNetAmount, changeData]);

	React.useEffect(() => {
		getFinanceRequest("/wallet/balance", {}).then((res) => {
			const balance = res as Balance;
			setAvailableBalance(balance.availableBalance);
		});
	}, []);

	return (
		<div className={styles["benefit-riskFactors"]}>
			<div>
				<div className={styles["title"]}>{t(keys.returnAndRiskFactors)}</div>
				<div className={styles["content"]}>
					<div>
						<div>{t(keys.expectedApr)}</div>
						<div>
							<Input
								placeholder={">0"}
								value={expectedApr}
								onChange={(e) => onFactorsChangeEvent(e, setExpectedApr, setExpectedAprStatus)}
								status={expectedAprStatus}
								suffix={<div style={{ color: "#A8AAB7" }}>%</div>}
							/>
						</div>
					</div>
					<div>
						<div>{t(keys.estimatedMaxDrawdown)}</div>
						<div>
							<Input
								placeholder={"-100~0"}
								value={estimatedMaxDrawdown}
								onChange={(e) =>
									onFactorsChangeEvent(e, setEstimatedMaxDrawdown, setEstimatedMaxDrawdownStatus)
								}
								status={estimatedMaxDrawdownStatus}
								suffix={<div style={{ color: "#A8AAB7" }}>%</div>}
							/>
						</div>
					</div>
					<div>
						<div>{t(keys.stopLossLine)}</div>
						<div>
							<Input
								placeholder={"-100~0"}
								value={stopLossLine}
								onChange={(e) => onFactorsChangeEvent(e, setStopLossLine, setStopLossLineStatus)}
								status={stopLossLineStatus}
								suffix={<div style={{ color: "#A8AAB7" }}>%</div>}
							/>
						</div>
					</div>
					<div>
						<div>
							{t(keys.safetyNetAmoun)}({t(keys.optional)})
						</div>
						<div>
							<Input
								placeholder={"≥100"}
								value={safetyNetAmount}
								onChange={(e) => onFactorsChangeEvent(e, setSafetyNetAmount, setSafetyNetAmountStatus)}
								suffix={<div style={{ color: "#A8AAB7" }}>USDT</div>}
								status={safetyNetAmountStatus}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={styles["bottom"]}>
				<div>
					CIS {t(keys.availableBalance)}(USDT)<span>{availableBalance}</span>
				</div>
				<div>
					<Button>{t(keys.deposit)}</Button>
				</div>
			</div>
		</div>
	);
};
