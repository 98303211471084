import { getFundRequest } from "@shared/utils/request";

async function fetchFunds(page: number, size: number) {
	return await getFundRequest("/fund/managerFunds", { page, size });
}

async function fetchSummary() {
	return await getFundRequest("/fund/settlementSummary", {});
}

async function fetchCumulativeReturn(fundId: string) {
	return await getFundRequest(`/public/fund/cumulativeReturnCurve/${fundId}`, {});
}

export { fetchFunds, fetchSummary, fetchCumulativeReturn };
