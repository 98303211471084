const emailReg = new RegExp("^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$"); //email format
const pwdReg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$"); //8-16 digits or letter case sensitive

export function isValidEmail(text: string) {

	return emailReg.test(text);
};

export function isValidPwd(text: string) {

	return pwdReg.test(text);
};