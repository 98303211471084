import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import styles from "./Layout.less";
import TopNavigator from "../TopNavigator/TopNavigator";
import PageBottom from "../PageBottom/PageBottom";
import { getKeyThroughPath } from "@shared/utils/pathHandler";

export default function Layout() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const path = getKeyThroughPath(pathname);
	const [currentKey, setCurrentKey] = useState<string>(path);
	useEffect(() => {
		if (pathname === "/") {
			setCurrentKey("fundManagement");
			navigate("fundManagement");
		}
		setCurrentKey(path);
	}, [navigate, pathname, path]);

	return (
		<div className={styles["layout-container"]}>
			<div className={styles["layout-top"]}>
				<TopNavigator currentKey={currentKey} setCurrentKey={setCurrentKey} />
			</div>
			<Outlet />
			<PageBottom />
		</div>
	);
}
