import useLanguage from "@shared/hooks/useLanguage";
import { Key } from "react";
import { ReferenceInvestmentReport } from "../typings/importInvestmentReport";
import styles from "@/manager/components/createFund/StepsBar.less";
import doneIcon from "src/shared/assets/png/doneIcon.png";
import { Step } from "../typings/steps";
import ImportStrategyInvestmentReport from "../components/createFund/ImportStrategyInvestmentReport";
import { FundFactorParams } from "../typings/fundFactor";
import { FundFactor } from "../components/createFund/fundFactor/FundFactor";

interface Props {
	current: number;
	referenceStrategies: Key[];
	setReferenceStrategies: (val: Key[]) => void;
	setReferenceInvestmentReport: (val: ReferenceInvestmentReport) => void;
	setHasReferenceStrategies: (val: boolean) => void;
	setHasReferenceInvestmentReport: (val: boolean) => void;
	validate: boolean;
	setValidate: (val: boolean) => void;
	setReferenceStrategiesInit: (val: boolean) => void;
	setReferenceInvReportInit: (val: boolean) => void;
	submit: boolean;
	setSubmit: (val: boolean) => void;
	fundFactorParams: FundFactorParams;
	setFundFactorParams: React.Dispatch<React.SetStateAction<FundFactorParams>>;
	needCheckForm: boolean;
}

export function useSteps({
	current,
	referenceStrategies,
	setReferenceStrategies,
	setReferenceInvestmentReport,
	setHasReferenceStrategies,
	setHasReferenceInvestmentReport,
	validate,
	setValidate,
	setReferenceStrategiesInit,
	setReferenceInvReportInit,
	submit,
	setSubmit,
	fundFactorParams,
	setFundFactorParams,
	needCheckForm,
}: Props) {
	const { t, keys } = useLanguage();
	const computeIcon = (current: number, index: number) => {
		return current > index ? <img src={doneIcon} className={styles["steps-icon"]} /> : null;
	};
	const steps: Step[] = [
		{
			index: 0,
			title: t(keys.fundElements),
			content: (
				<FundFactor
					params={fundFactorParams}
					changeParams={setFundFactorParams}
					needCheckForm={needCheckForm}
				/>
			),
			icon: computeIcon(current, 0),
		},
		{
			index: 1,
			title: t(keys.referenceStrategyAndInvestmentReport),
			content: (
				<ImportStrategyInvestmentReport
					referenceStrategies={referenceStrategies}
					setReferenceStrategies={setReferenceStrategies}
					fundAccount={fundFactorParams.baseInformation.fundAccount}
					setReferenceInvestmentReport={setReferenceInvestmentReport}
					setHasReferenceStrategies={setHasReferenceStrategies}
					setHasReferenceInvestmentReport={setHasReferenceInvestmentReport}
					validate={validate}
					setValidate={setValidate}
					setReferenceStrategiesInit={setReferenceStrategiesInit}
					setReferenceInvReportInit={setReferenceInvReportInit}
					submit={submit}
					setSubmit={setSubmit}
				/>
			),
			icon: computeIcon(current, 1),
		},
	];
	const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }));
	return { steps, items };
}
