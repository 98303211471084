import React from "react";
import backIcon from "src/shared/assets/png/backIcon.png";
import styles from "./NavigateHeader.less";
import { useNavigate } from "react-router-dom";
import useLanguage from "@shared/hooks/useLanguage";

export default function NavigateHeader() {
	const navigate = useNavigate();
	const { t, keys } = useLanguage();
	return (
		<div className={styles["navigate-header"]}>
			<img src={backIcon} onClick={() => navigate("fundManagement/myfund")} />
			<span onClick={() => navigate("fundManagement/myfund")}>{t(keys.fundManagement)}&nbsp;/&nbsp;</span>
			<span>{t(keys.newFund)}</span>
		</div>
	);
}
