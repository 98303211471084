import { LoginBody } from "./login";
import { Response } from "src/shared/utils/request";

export function parseUserInfo(loginResult: Response<LoginBody>) {
	const { body } = loginResult;

	return {
		mail: body.user.mail,
		accessToken: body.token.accessToken,
		refreshToken: body.token.refreshToken,
		features: body.features,
	};
};