import React from "react";
import styles from "./ReturnCurveModal.less";
import { Modal } from "antd";
import useLanguage from "@shared/hooks/useLanguage";
import ReturnChart from "./ReturnChart";

interface Props {
	fundId: string;
	isModalOpen: boolean;
	setIsModalOpen: (value: boolean) => void;
}

export default function ReturnCurveModal(props: Props) {
	const { fundId, isModalOpen, setIsModalOpen } = props;
	const { t, keys } = useLanguage();

	return (
		<Modal
			title={t(keys.cumulativeReturn)}
			open={isModalOpen}
			onCancel={() => {
				setIsModalOpen(false);
			}}
			footer={null}
			maskClosable={false}
			wrapClassName={styles["return-curve-container"]}
			centered={true}
		>
			<div>
				<ReturnChart fundId={fundId}></ReturnChart>
			</div>
		</Modal>
	);
}
