import { clearLocalStorage } from "@/login/model/authService";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export const useLogout = () => {
	const navigate = useNavigate();

	const handleLogoutClick = useCallback(() => {
		clearLocalStorage();
		navigate("/login");
	}, [navigate]);
	return { handleLogoutClick };
};
