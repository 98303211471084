/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input } from "antd";
import styles from "./Login.less";
import { t } from "i18next";
import { keys } from "@shared/i18next/languages/keys";
import useLanguage from "@shared/hooks/useLanguage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountdownProgressManager from "./countdownProgressManager";
import { isValidEmail, isValidPwd } from "@/login/model/validator";
import visibleIcon from "src/shared/assets/png/visibleIcon.png";
import invisibleIcon from "src/shared/assets/png/invisibleIcon.png";
import { createAccount, getVerificationCode, isMailExisted, resetPwd } from "@/login/model/registrationService";
import { parseUserInfo } from "@/login/model/userParser";
import { saveToLocalStorage, tryLogin } from "@/login/model/authService";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface Props {
	isResetingPwd?: boolean;
}

export default function CreateAccount(props: Props) {

	const { t, keys } = useLanguage();
	const [visitorId, setVisitorId] = useState<string>("");

	const MAX_SECONDS = 60;
	const countdownManager = new CountdownProgressManager(props.isResetingPwd ? "resetPwd" : "createAccount", MAX_SECONDS);
	const [remainSeconds, setRemainSeconds] = useState<Number>(countdownManager.getRemainTime());

	const [waitForConfirmation, setWaitForConfirmation] = useState(false);
	const [emailAddress, setEmailAddress] = useState<string>("");
	const [verificationCode, setVerificationCode] = useState<string>("");
	const [emailAddressHelp, setEmailAddressHelp] = useState<string>();
	const [verificationCodeHelp, setVerificationCodeHelp] = useState<string>();
	const [password, setPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [passwordHelp, setPasswordHelp] = useState<string>();
	const [confirmPasswordHelp, setConfirmPasswordHelp] = useState<string>();

    const navigate = useNavigate();

    const inputEmailVerified = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmailAddress(e.target.value);
	};

	const inputVerificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(e.target.value);
	};

    const inputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const inputPasswordAgain = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(e.target.value);
	};

    const waitForNext = async() => {
		if (isValidEmail(emailAddress)) {
			const mailExisted = await isMailExisted(emailAddress) as boolean;
			props.isResetingPwd ? emailNotExisted(mailExisted) : emailExisted(mailExisted);
		} else {
			setEmailAddressHelp(t(keys.pleaseInputValidEmailAddress));
		}
	};

	const emailNotExisted = (mailExisted: boolean) => {
		if (!mailExisted) {
			setEmailAddressHelp("Email not registered");
		} else {
			setWaitForConfirmation(true);
			setEmailAddressHelp("");
			getCode(emailAddress);
		}
	};

	const emailExisted = (mailExisted: boolean) => {
		if (mailExisted) {
			setEmailAddressHelp(t(keys.emailAlreadyRegistered));
		} else {
			setWaitForConfirmation(true);
			setEmailAddressHelp("");
			getCode(emailAddress);
		}
	};

	const getCode = async (emailAddress: string) => {
		const sendCodeSuccess = await getVerificationCode(emailAddress);
		setVerificationCodeHelp(sendCodeSuccess?.data.code === "OK" ? "" : "Send verification code too frequency");
	};

    const validateEmail = () => {
		if (!isValidEmail(emailAddress)) {
			setEmailAddressHelp(t(keys.pleaseInputValidEmailAddress));
			return false;
		} else {
			setVerificationCodeHelp("");
			return true;
		}
	};

	const validatePwd = () => {
		if (!isValidPwd(password)) {
			setPasswordHelp(t(keys.eightToSixteenDigitsOrLetterCaseSensitive));
			return false;
		}
		if (confirmPassword !== password) {
			setPasswordHelp("");
			setConfirmPasswordHelp(t(keys.passwordDifferent));
			return false;
		} else {
			setPasswordHelp("");
			setConfirmPasswordHelp("");
			return true;
		}
	};

	const finishRegistration = async() => {
		if (validateEmail() && validatePwd()) {
			const isSuccess = props.isResetingPwd ?
				await resetPwd(emailAddress, password, verificationCode) : await createAccount(emailAddress, password, verificationCode);

			if (isSuccess?.data.code === "OK") {
				login(emailAddress, password, navigate, visitorId);
				countdownManager.clearRemainTime();
			} else if (isSuccess?.data.code === "INCORRECT_VERIFICATION_CODE") {
				setVerificationCodeHelp(t(keys.pleaseInputCorrectVerificationCode));
			}
		}
	};

	const enableCountDown = () => {
		if (countdownManager.isReady()) {
			countdownManager.resetStartTime();
		}

		setInterval(() => {
			if (countdownManager.isRunning()) {
				setRemainSeconds(countdownManager.getRemainTime());
			}
		}, 1000);
	};

	useEffect(() => {
		FingerprintJS.load()
			.then((fp) => fp.get())
			.then((result) => {
				setVisitorId(result.visitorId);
			});
	}, []);

	useEffect(() => {
		setEmailAddressHelp("");
	}, [emailAddress]);

	useEffect(() => {
		setVerificationCodeHelp("");
	}, [verificationCode]);

	useEffect(() => {
		setPasswordHelp("");
	}, [password]);

	useEffect(() => {
		setConfirmPasswordHelp("");
	}, [confirmPassword]);

	useEffect(() => {
		if (waitForConfirmation) {
			enableCountDown();
		}
	}, [waitForConfirmation]);

	useEffect(() => {
		if (countdownManager.isReady()) {
			setWaitForConfirmation(false);
		}
	}, [remainSeconds]);

    return (
        <>
            <div className={styles["header"]}>
                {props.isResetingPwd ?
                    <div>{t(keys.resetPassword)}</div> : <div>{t(keys.register)}</div>
                }
			</div>
            <div className={styles["user-information"]}>
				<Form>
					<Form.Item label={emailLabel()} help={emailAddressHelp} colon={false}>
						<Input
							status={emailAddressHelp === "" ? "" : "error"}
							onChange={inputEmailVerified}
							data-testid="emailVerifiedH"
							placeholder={t(keys.emailTip)}
						/>
					</Form.Item>
					<Form.Item label={verificationCodeLabel()} help={verificationCodeHelp} colon={false}>
						<Input
							status={verificationCodeHelp === "" ? "" : "error"}
							onChange={inputVerificationCode}
							data-testid="password"
							addonAfter={verificationCodeButton(waitForConfirmation, waitForNext, remainSeconds)}
							placeholder={t(keys.pleaseInput)}
						/>
					</Form.Item>
					<Form.Item label={pwdLabel()} help={passwordHelp} colon={false}>
						<Input.Password
							status={passwordHelp === "" ? "" : "error"}
							onChange={inputPassword}
							data-testid="password"
							placeholder={t(keys.eightToSixteenDigitsOrLetterCaseSensitive)}
							iconRender={(visible) =>
									<img src={visible ? visibleIcon : invisibleIcon} style={{ width: "16px", height: "16px" }} />
							}
						/>
					</Form.Item>
					<Form.Item label={confirmPwdLabel()} help={confirmPasswordHelp} colon={false}>
						<Input.Password
							status={confirmPasswordHelp === "" ? "" : "error"}
							onChange={inputPasswordAgain}
							data-testid="confirmPassword"
							placeholder={t(keys.pleaseInput)}
							iconRender={(visible) =>
								<img src={visible ? visibleIcon : invisibleIcon} style={{ width: "16px", height: "16px" }} />
							}
						/>
					</Form.Item>
				</Form>
			</div>
			<div className={styles["finish"]}>
				<Button
					className={styles["application-btn"]}
					onClick={finishRegistration}
				>
					{t(keys.finished)}
				</Button>
			</div>
        </>
    );
}

const verificationCodeButton = (waitForConfirmation: boolean, waitForNext: () => void, remainSeconds: Number) => {
	return (
		<>
			{(waitForConfirmation ?
				<div className={styles["reacquire"]}>
					<span>{t(keys.resend)}({remainSeconds.toString()})</span>
				</div>
				:
				<div className={styles["send"]}>
					<span onClick={waitForNext}>{t(keys.send)}</span>
				</div>
			)}
		</>
	);
};

const login = async(emailAddress: string, password: string, navigate: any, visitorId: string) => {

	const result = (await tryLogin(emailAddress, password, visitorId))?.data;

	if (result?.code === "OK") {
		const userInfo = parseUserInfo(result);
		await saveToLocalStorage(userInfo);
	}
	navigate("/fundManagement/myfund");
};

const emailLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.email)}:</span>
		</div>
	);
};

const verificationCodeLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.verificationCode)}:</span>
		</div>
	);
};

const pwdLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.setPassword)}:</span>
		</div>
	);
};

const confirmPwdLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.confirmPassword)}:</span>
		</div>
	);
};