import SwitchIdentities from "./SwitchIdentities";
import UserInfo from "./UserInfo";
import LangChooser from "./LangChooser";

export default function UserMenu() {
	return (
		<>
			<SwitchIdentities />
			<UserInfo />
			<LangChooser />
		</>
	);
}