import { Key } from "antd/es/table/interface";
import { StrategiesTable } from "../typings/importStrategies";

export const getTableConfig = (
	setReferenceStrategies: (val: Key[]) => void,
	setReferenceStrategiesInit: (val: boolean) => void
) => {
	const rowSelection = {
		onChange: (selectedRowKeys: Key[], selectedRows: StrategiesTable[]) => {
			setReferenceStrategies(selectedRowKeys);
			setReferenceStrategiesInit(true);
		},
		columnWidth: 16,
	};
	return rowSelection;
};
