export const en = {
	fundParadise: "Fund Paradise",
	fundDetails: "Fund Details",
	currentProfit: "Current Profit",
	totalCapital: "Total Capital",
	walletBalance: "Wallet Balance",
	deposit: "Deposit",
	frozenMargin: "Frozen Margin",
	availableBalance: "Available Balance",
	allHistoricalProfit: "Historical Profit",
	myFunds: "My Funds",
	transactionSummary: "Transaction Summary",
	quantFund: "Quant Fund",
	valueFund: "Value Fund",
	themeFund: "Theme Fund",
	comingSoon: "Coming Soon",
	name: "Name",
	expectedApr: "Expected APR",
	apr: "APR",
	aum: "AUM",
	followedInvestors: "Investors",
	return: "Return",
	sinceInception: "Since Inception",
	follow: "Follow",
	addInvestment: "Add",
	addFollow: "Add",
	managerSafetyNet: "Manager Safety Net",
	alreadyRealTradingVerified: "∬ M of Real Trading Verified",
	alreadyBacktestingVerified: "∬ M Backtesting Verified ",
	cumulativeReturn: "Cumulative Return",
	followSetting: "Follow Setting",
	fund: "Fund",
	capital: "Capital",
	exchangeAccount: "Exchange Account",
	importExchangeAccount: "Import Exchange Account",
	exchangeAccountBalance: "Exchange Account Balance",
	orderPercentage: "Order Percentage",
	ratioSingleOrderAmountToCapital: "Single order amount / Capital",
	positionRatioEqualsFundManagerMinAmountMoreThanTenUSDT: "Position ratio equals to fund manager's, min amount ≥ 10 USDT",
	investmentPeriods: "Investment Periods",
	range: "Range",
	inputPeriods: "Input periods",
	followFee: "Fee",
	managementFee: "Management Fee",
	commission: "Commission",
	periodAt: "Period ∬ ",
	end: "End",
	insufficientBalancePleaseDepositAsap: "Insufficient balance, please deposit ASAP",
	pleaseImportExchangeAccountFirst: "Please import exchange account first",
	startOfPeriodFrozenMargin: "Start-of-Period Frozen Margin",
	endOfPeriodSettlement: "End-of-Period Settlement",
	frozenMarginReleasedToFollowerAccountIfLoss: "Frozen margin will be released to follower, s CIS account if there is a loss ",
	oneFifthCurrentProfitAsSharedDeductedIfProfitRemainingReleased: "20% of actual current profit as shared profit will be deducted from frozen margin if there is a profit, the remaining frozen margin will be released to CIS account ",
	followed: "Followed",
	selectAccount: "Select Account",
	balance: "Balance",
	runtime: "Runtime",
	dayAt: " ∬ D",
	nextSettlement: "Next Settlement ",
	fundEndDate: "Fund End Date",
	realNetProfit: "Net Profit",
	investmentReport: "Investment Report",
	fundStrategy: "Fund Strategy",
	fundElements: "Fund Elements",
	instruction: "Instruction",
	pair: "Pair",
	leverageTimes: "Leverage",
	initialBacktestingCapital: "Backtesting Initial Capital",
	positionMode: "Position Mode",
	dateRange: "Date Range",
	cumulativeReturnCurve: "Cumulative Return",
	pAndL: "P&L",
	tradeTimes: "Trade Times",
	winRatio: "Win Ratio",
	fundRiskReturnCharacteristics: "Fund Risk-Return Characteristics",
	expectedDrawdown: "Expected Drawdown",
	renewalPeriod: "Renewal Period",
	inceptionDate: "Inception Date",
	subscriptionClosingDate: "Subscription Closing Date",
	operationMode: "Operation Mode",
	openEnd: "Open-End",
	closedEnd: "Closed-End",
	incentiveFee: "Incentive Fee",
	stopLossRatio: "Stop-Loss Ratio",
	tradingTargets: "Trading Targets",
	futures: "Futures",
	leverage: "Leverage",
	followedInvestorsTotal: "Followed Investors",
	totalProfitOfFollowedInvestors: "Total Profit of Followed Investors",
	closed: "Closed",
	tradingRecord: "Trading Record",
	tradingRecordShort: "Record",
	stop: "Stop",
	stopFollowing: "Stop Following",
	singleCapitalAmountToAvailableAmountConsistentWithManagerAndMinimumTen: "The proportion of the single order amount to the available amount in capital is consistent with the fund manager's position ratio, The minimum amount is 10 USDT",
	stopCopyAndOrdersStopOtherwiseExistOpenCopyingPositions: "After stopping, copying orders will immediately stop, If there are open positions from copying, they will be automatically closed at market price",
	settlementAfterStoppingDayFrozenMarginReleasedFollowingDay: "Settlement occurs immediately after stopping, the frozen margin will be released instantly",
	confirm: "Confirm",
	cancel: "Cancel",
	settled: "Settled",
	current: "Current",
	pending: "Pending",
	marginFrozen: "Margin Frozen",
	marginReleased: "Margin Released",
	safetyNetAmountRefunded: "Safety Net Amount Refunded",
	details: "Details",
	time: "Time",
	type: "Type",
	initialFollowing: "Initial Following",
	additionalFollowing: "Additional Following",
	amount: "Amount",
	account: "Account",
	paidAmount: "Paid Amount",
	lossAmount: "Loss Amount",
	crypto: "Crypto",
	dealTime: "Time",
	direction: "Direction",
	filled: "Filled",
	price: "Price",
	summary: "Summary",
	profit: "Profit",
	update: "Update",
	exchange: "Exchange",
	pleaseInput: "Please input",
	hedgeMode: "Hedge Mode",
	pleaseEnsureSameAPIKeySecretAsBefore: "Please ensure the new API Key and Secret is from the same exchange account as before",
	register: "Register",
	email: "Email",
	verificationCode: "Verification Code",
	send: "Send",
	next: "Next",
	previous: "Previous",
	alreadyHaveAccount: "Already have an account",
	toLogIn: "Log in",
	noAccount: "No account",
	registerNow: "Register",
	passwordForgotten: "Password Forgotten",
	emailTip: "Email will be your CIS login account",
	emailNotRegistered: "Email not registered",
	resend: "Resend",
	pleaseInputValidEmailAddress: "Please input a valid email address",
	emailAlreadyRegistered: "This email is already registered",
	pleaseInputCorrectVerificationCode: "Please input a correct verification code",
	verificationCodeExpired: "This verification code has expired",
	setPassword: "Set Password",
	eightToSixteenDigitsOrLetterCaseSensitive: "8 - 16 digits and letter combinations, case-sensitive",
	confirmPassword: "Confirm Your Password",
	passwordDifferent: "The two password entries do not match",
	registeredSuccess: "Registered successfully",
	logIn: "Log In",
	user: "User",
	password: "Password",
	incorrectAccountPasswordPleaseTryAgain: "Incorrect account or password, please try again",
	resetPassword: "Reset Password",
	setNewPassword: "Set New Password",
	finished: "Done",
	success: "Success",
	withdraw: "Withdraw",
	logOut: "Log Out",
	followTrader: "Copy Trader",
	fundManager: "Fund Manager",
	investor: "Investor",
	selectWalletProtocol: "Select Wallet Protocol",
	securityTip: "Security tip",
	confirmSelectedProtocol: "Confirm the selected protocol",
	completeTransferThroughWallet: "Use wallet app to scan QR code or copy wallet address below to complete transfer",
	copyAddress: "Copy Address",
	accumulativeDeposit: "Cumulative Deposit",
	cumulativeWithdrawal: "Cumulative Withdrawal",
	arrivalTime: "Arrival Time",
	amountReceived: "Amount",
	protocol: "Protocol",
	reset: "Reset",
	inTotal: "∬ in Total",
	walletAddress: "Wallet Address",
	profitDetails: "Details",
	investmentProfit: "Profit",
	insufficientFrozenMargin: "Insufficient Frozen Margin",
	insufficientFrozenMarginPleaseAdd: "Insufficient frozen margin, please add",
	addMarginShort: "Add",
	automaticStop: "Automatic Stoping",
	fundStoppedBecauseEstimatedFeeLargerThanFrozenMargin: "Fund ∬ has been automatically stopped due to estimated fee is larger than frozen margin",
	view: "View",
	frozenFromAvailableBalanceInWallet: "frozen from available balance in CIS wallet",
	oneFifthProfitDeductedAsFeesFromFrozenMarginAfterSettlement: "20% of the profit will be deducted as fees from the frozen margin after fund settlement",
	currentFund: "Current",
	historicalFund: "Historical",
	insufficientMargin: "Insufficient",
	addMargin: "Add Frozen Margin ",
	estimatedFee: "Estimated Fee",
	pleaseAddFrozenMargin: "please add frozen margin ",
	stopFollowWhenMoreThanOneHundred: "Following will automatically stop when it ≥100%",
	historicalProfit: "Historical Profit",
	totalProfitAfterSettlement: "Total profit after each settlement",
	unsettledProfit: "Unsettled Profit",
	expectedProfit: "Expected Profit",
	ProfitAfterDeductingFeeFromUnsettledProfit: "Profit after deducting Fee",
	successCancel: "Success",
	addFrozenMargin: "Add Frozen Margin",
	addAmount: "Add Amount",
	inputAmount: "Input Amount",
	settlementRecord: "Settlement Record",
	settlementTime: "Time",
	stopFollowingSettlement: "Stop Following Settlement ",
	fee: "Fee",
	netProfit: "Net Profit",
	fundPeriodicSettlement: "Fund Periodic Settlement",
	depositAndWithdrawa: "Deposit & Withdrawal",
	all: "All",
	marginDeducted: "Margin Deducted",
	initialFollow: "Initial",
	addFollowingAmount: "Add Following Amount",
	fundEnd: "Fund End",
	feeRate: "Fee Rate",
	loss: "Loss",
	refundRate: "Refund Rate",
	submitted: "Submitted, expected to arrive within 24h",
	currentCapital: "Current Capital",
	followAccount: "Account",
	myWallet: "Wallet",
	start: "Start",
	upTo: "End",
	lastSettlement: "Last Settlement",
	openLong: "Open Long",
	closeLong: "Close Long",
	openShort: "Open Short",
	closeShort: "Close Short",
	perpetual: "Perpetual",
	spot: "Spot",
	contentDetails: "Details",
	capitalAmountVerify: "Please input correct capital amount",
	orderPercentageVerify: "Please input correct order percentage",
	copy: "Copy",
	privacyPolicy: "Privacy Policy",
	managerDetails: "Manager Details",
	statisticsTarget: "Statistics",
	allTrade: "All",
	long: "Long",
	short: "Short",
	finalProfit: "Net Profit",
	totalProfit: "Total Profit",
	totalLoss: "Total Loss",
	profitAndLossRate: "P&L Ratio",
	winRate: "Win Ratio",
	profitNumber: "Profit Times",
	lossNumber: "Loss Times",
	averageNetProfit: "Average Net Profit",
	averageProfit: "Average Profit",
	averageLoss: "Average Loss",
	averageProfitAndLossRate: "Average P&L Ratio",
	maxProfit: "Max Profit",
	maxLoss: "Max Loss",
	maxProfitOrTotalProfit: "Max Profit / Total Profit",
	maxLossOrTotalLoss: "Max Loss / Total Loss",
	netProfitAndMaxLoss: "Net Profit / Max Loss",
	maxProfitNumber: "Max Continuous Profit Times",
	maxLossNumber: "Max Continuous Loss Times",
	averageHoldingPeriod: "Average Holding Period",
	averageProfitPeriod: "Average Profit Period",
	averageLossPeriod: "Average Loss Period",
	totalTradeCost: "Total Fees",
	sharpeRatio: "Sharpe Ratio",
	totalPeriod: "Total Period",
	holdingPeriodOrTotalPeriod: "Holding Period / Total Period",
	holdingPeriod: "Holding Period",
	maxNonTrainingPeriod: "Max No-position Period",
	holdingBars: "Holding Bars",
	maxWithdrawalRate: "Max Drawdown",
	drawdownValue: "Drawdown Value",
	occurTime: "Time",
	maxDrawdownValue: "Max Drawdown Value",
	drawdown: "Drawdown",
	allocationRisk: "Allocation Risk",
	maxDrawdownWithoutLeverage: "Max Drawdown for a Single Position without Leverage",
	liquidationTimes: "Liquidation Times",
	newFund: "New Fund",
	settledSharedProfit: "Settled Shared Profit",
	unsettledSharedProfit: "Unsettled Shared Profit",
	frozenSafetyNet: "Frozen Safety Net",
	investorProfits: "Investor Profit",
	totalSharedProfit: "Total Shared Profit",
	state: "State",
	open: "Open",
	expire: "End",
	profitDetail: "Profit Details",
	alreadySettled: "Settled",
	investorHistoricalProfit: "Investor Historical Profit",
	managerSettledSharedProfit: "Manager Settled Shared Profit",
	unsettled: "Unsettled",
	managerExpectedSharedProfit: "Manager Expected Shared Profit",
	sharedProfitRatio: "Shared Profit Ratio",
	settleTime: "Time",
	fundRange: "Date Range",
	investorProfit: "Investor Profit",
	cisSharedProfit: "CIS Shared Profit",
	managerSharedProfit: "Manager Shared Profit",
	cisExpectedSharedProfit: "CIS Expected Shared Profit",
	referenceStrategyAndInvestmentReport: "Reference Strategy and Investment Report",
	basicInformation: "Basic Information",
	managerAccountRule: "A manager's CIP trading account can only be used for one fund",
	investorPlaceOrderRule: "Investors will place orders automatically by following the trading actions of this account fully",
	expirationDate: "Renewal Period",
	settledPeriod: "Settled Period",
	month: "Month",
	quarter: "Quarter",
	sixMonths: "6 Months",
	year: "Year",
	cipFundAccount: "CIP Fund Account",
	followMode: "Follow Mode",
	fullFollowRatio: "Full Follow Ratio",
	customRatio: "Custom ratio",
	fullFollowRatioTooltip: "Investor position ratio equals to fund manager, min amount ≥ 10 USDT",
	customRatioTooltip: "Investor position ratio is set by himself, min amount ≥ 10 USDT",
	returnAndRiskFactors: "Return and Risk Factors",
	estimatedMaxDrawdown: "Estimated Max Drawdown",
	stopLossLine: "Stop Loss Line",
	safetyNetAmoun: "Safety Net Amoun",
	optional: "Optional",
	tradingTargetRange: "Trading Target Range",
	spotUnlimited: "Spot Unlimited",
	rangeDescription: "Range Description",
	leverageRange: "Leverage Range",
	add: "Add",
	minLeverage: "Min Leverage",
	maxLeverage: "Max Leverage",
	selectPair: "Select Pair",
	referenceStrategy: "Reference Strategy",
	referenceReport: "Reference Report",
	publish: "Publish",
	publishDone: "Publish Done",
	networkErrorTip: "Network error, please check the network or try again later",
	nameOccupied: "This name is already occupied, please change it",
	fundManagement: "Fund Management",
	digitsLettersUnderscores: "4-32 digits, letters, _, -, .",
	startDate: "Start Date",
	finishDate: "End Date",
	alias: "Alias",
	nameGeneral: "Name",
	period: "Period",
	lever: "Leverage",
	maxDrawdown: "Max Drawdown",
	fixedPosition: "Fixed",
	dynamicPosition: "Dynamic",
	strategyPosition: "Strategic",
	referenceStrategyAndInvestmentReportVerify: "Reference at least one strategy or investment report",
	apiKeyAndSecretVerify: "Please input API Key and API Secret correctly",
	noData: "No data",
	apiKeyVerify: "This API Key has been imported, please change it",
	sinceIncep: "Since Investment",
	uncheckDefaultSecurityControlsInBinanceWebAPIManagement: "To prevent transaction failures due to API expiration, uncheck [Default Security Controls] in Binance Web API Management",
	importedTradingAccountMustBeHedgeMode: "Imported trading account must be hedge mode",
	exchangeAccountInTradingModeAndCannotBeUpdated: "This exchange account is currently in trading mode and cannot be updated",
	updated: "Updated",
	pleaseEnsureNewApiKeySecretFromSameExchangeAccountAsBefore: "Please ensure the new API Key/Secret is from the same exchange account as before",
	failedTooManyTimesPleaseTryAgainTomorrow: "Failed too many times, Please try again tomorrow",
	select: "Select",
	selected: "Selected",
	fundManagerStrategyBank: "Fund Manager Strategy Bank",
	notifications: "Notifications",
	invalid: "Invalid",
	allDeal: "All",
	successful: "Successful",
	reason: "Reason",
	submittedPleaseCheckInHistoricalFunds: "Submitted, Please check in Historical Funds",
	stopping: "Stopping",
	failed: "Failed",
	closureFailurePleaseUpdateYourApiKey: "API Key detected as invalid, causing closure failure, Please update your API Key",
	ifUpdatedPleaseWaitForSystemAutomaticallyClosePosition: "If you've already updated, please wait for the system to automatically close the position",
	cisInvestmentAgreement: "CIS investment Agreement",
	agree: "Agree",
	loading: "Loading",
	loadingFailedPleasePullDownOrClickReload: "Loading failed. Please pull down or click to reload",
	noMoreData: "No more data",
	copied: "Copied",
	insufficientFundFrozenMargin: "Your fund ∬ has insufficient frozen margin, please please add it as soon as possible",
	failedTag: "Failed",
	reload: "Reload",
	backtestingReturn: "Return",
	annualizedReturn: "Annualized Return",
	sort: "Sort",
	filter: "Filter",
	realTradingVerified: "Real Trading Verified",
	backtestingVerified: "Backtesting Verified",
	followingFailedToast: "The fund cancellation was unsuccessful, and following is not currently supported",
	automaticallyStoppedPopover: "Fund has been automatically stopped due to estimated fee is larger than frozen margin",
	agreementToTheTermsOfService: "Registration implies agreement to the",
	termsOfService: "TERMS OF SERVICE",
	selectAtLeastOneOption: "Select at least one option",
	Refunded: "Refunded",
	capitalLossAmount: "Loss",
};
