import React from "react";
import styles from "./ReturnChart.less";
import ReactEcharts from "echarts-for-react";
import { useGetReturnCurve } from "@/manager/hooks/myFund";
import { Spin } from "antd";

interface Props {
	fundId: string;
}

export default function ReturnChart(props: Props) {
	const { fundId } = props;
	const { returnCurve, isLoading } = useGetReturnCurve(fundId);
	const option = getOption(returnCurve);

	return isLoading ? (
		<div className="spin-outside">
			<Spin size="large" />
		</div>
	) : (
		<ReactEcharts
			option={option}
			opts={{ width: "auto", height: "auto" }}
			style={{ width: "30em", height: "14.875em" }}
		/>
	);
}

const getOption = (data: [string, number][]) => {
	return {
		xAxis: {
			type: "category",
			show: true,
			boundaryGap: false,
			axisTick: {
				show: false,
			},
			axisLabel: {
				show: true,
			},
			axisLine: {
				show: false,
			},
		},
		yAxis: {
			type: "value",
			splitLine: {
				show: true,
				lineStyle: {
					type: "dotted",
					width: 3,
				},
			},
			axisLabel: {
				margin: 8,
				formatter: (value: number) => {
					return `${(value * 100).toFixed(2)}%`;
				},
			},
		},
		color: {
			type: "linear",
			x: 0,
			y: 0,
			x2: 0,
			y2: 1,
			colorStops: [
				{
					offset: 0,
					color: "#ffffff00",
				},
				{
					offset: 1,
					color: "#B0BCFF",
				},
			],
			global: false,
		},
		series: [
			{
				data: data,
				type: "line",
				lineStyle: {
					color: "#3673E8",
					width: 1,
				},
				areaStyle: {},
				symbol: "none",
				smooth: true,
			},
		],
		grid: {
			top: "5%",
			left: 60,
			right: 60,
			bottom: "13%",
		},
	};
};
