import React from "react";
import styles from "./FundFactor.less";
import { BasicInformation } from "./BasicInformation";
import { BenefitAndRiskFactors } from "./BenefitAndRiskFactors";
import { RangeOfTradingTargets } from "./RangeOfTradingTargets";
import { FundFactorParams } from "@/manager/typings/fundFactor";

interface FactorParams {
	params: FundFactorParams;
	changeParams: React.Dispatch<React.SetStateAction<FundFactorParams>>;
	needCheckForm: boolean;
}

export const FundFactor = (factorParams: FactorParams) => {
	const { params, changeParams, needCheckForm } = factorParams;
	const [basicData, setBasicData] = React.useState(params.baseInformation);
	const [benefitData, setBenefitData] = React.useState(params.benefitAndRiskFactors);
	const [rangeData, setRangeData] = React.useState(params.rangeOfTradingTargets);

	React.useEffect(() => {
		changeParams({
			baseInformation: basicData,
			benefitAndRiskFactors: benefitData,
			rangeOfTradingTargets: rangeData,
		});
	}, [basicData, benefitData, rangeData, changeParams]);

	return (
		<div className={styles["fund-factor"]}>
			<div>
				<BasicInformation data={basicData} changeData={setBasicData} needCheckForm={needCheckForm} />
				<BenefitAndRiskFactors data={benefitData} changeData={setBenefitData} needCheckForm={needCheckForm} />
			</div>
			<div>
				<RangeOfTradingTargets data={rangeData} changeData={setRangeData} needCheckForm={needCheckForm} />
			</div>
		</div>
	);
};
