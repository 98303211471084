import React from "react";
import styles from "./ChoosePairModal.less";
import { Modal, message } from "antd";
import useLanguage from "@shared/hooks/useLanguage";
import { PairItem, PairType } from "./RangeOfTradingTargets";
import chosenIcon from "@shared/assets/png/createFundChosenIcon.png";
import { getOracleRequest } from "@shared/utils/request";
import { ExchangeType, Pair } from "@shared/enum/enum";

interface ChoosePairModalParams {
	visible: boolean;
	closeModal: () => void;
	chosenPairs: string[];
	type: PairType;
	changeChosenPairs: React.Dispatch<React.SetStateAction<string[]>>;
	spotsPairs: string[];
	perpetualPairs: string[];
	pairItems: PairItem[];
}

export const ChoosePairModal = (props: ChoosePairModalParams) => {
	const { visible, closeModal, chosenPairs, type, changeChosenPairs, spotsPairs, perpetualPairs, pairItems } = props;
	const { t, keys } = useLanguage();
	const [chosenList, setChosenList] = React.useState<string[]>(chosenPairs);

	React.useEffect(() => {
		setChosenList(chosenPairs);
	}, [visible, chosenPairs]);

	const changeChosenItem = (pair: string) => {
		let items = [...chosenList];
		if (chosenList.includes(pair)) {
			items = items.filter((item) => item !== pair);
			setChosenList(items);
		} else {
			items.push(pair);
			setChosenList(items);
		}
	};

	const getPairs = (type: PairType) => {
		if (type === PairType.Contract) {
			return perpetualPairs.map((item) => {
				return (
					<div className={styles["item"]} key={item} onClick={() => changeChosenItem(item)}>
						{pairItems.filter((pair) => pair.id === item)[0].name}
						{chosenList.includes(item) ? <img src={chosenIcon} /> : null}
					</div>
				);
			});
		} else {
			return spotsPairs.map((item) => {
				return (
					<div className={styles["item"]} key={item} onClick={() => changeChosenItem(item)}>
						{pairItems.filter((pair) => pair.id === item)[0].name}
						{chosenList.includes(item) ? <img src={chosenIcon} /> : null}
					</div>
				);
			});
		}
	};

	const onHandleClose = () => {
		setChosenList(chosenPairs);
		closeModal();
	};

	const onHandleOk = () => {
		if (chosenList.length > 0) {
			changeChosenPairs(chosenList);
			closeModal();
		} else {
			message.error(t(keys.selectAtLeastOneOption));
		}
	};

	return (
		<>
			<Modal open={visible} onCancel={onHandleClose} width={"800px"} onOk={onHandleOk}>
				<div className={styles["content"]}>
					<div>{type === PairType.Contract ? "选择合约" : "选择现货"}</div>
					<div></div>
					<div>{getPairs(type)}</div>
				</div>
			</Modal>
		</>
	);
};
