import React from "react";
import styles from "./RangeOfTradingTargets.less";
import useLanguage from "@shared/hooks/useLanguage";
import { Button, Input } from "antd";
import { LeverageRanges, RangeOfTradingTargetsParam } from "@/manager/typings/fundFactor";
import { getOracleRequest } from "@shared/utils/request";
import { ExchangeType, Pair, StatusType } from "@shared/enum/enum";
import { checkProducts } from "@/manager/service/createFund";
import addIcon from "@shared/assets/png/createFundAddIcon.png";
import deleteIcon from "@shared/assets/png/createFundDeleteIcon.png";
import { ChoosePairModal } from "./ChoosePairModal";

interface RangeOfTradingTargetsParams {
	data: RangeOfTradingTargetsParam;
	changeData: React.Dispatch<React.SetStateAction<RangeOfTradingTargetsParam>>;
	needCheckForm: boolean;
}

export interface PairItem {
	name: string;
	id: string;
}

export enum PairType {
	Contract = "contract",
	Spot = "spot",
}

export const RangeOfTradingTargets = (props: RangeOfTradingTargetsParams) => {
	const { t, keys } = useLanguage();
	const { TextArea } = Input;
	const { data, changeData, needCheckForm } = props;
	const [description, setDescription] = React.useState<string>(data.products);
	const [descriptionStatus, setDescriptionStatus] = React.useState<StatusType>(StatusType.Normal);
	const [dataSource, setDataSource] = React.useState<LeverageRanges[]>(data.leverageRanges);
	const [spots, setSpots] = React.useState<string[]>(data.spots);
	const [contracts, setContracts] = React.useState<string[]>(data.contracts);
	const [choosePairModalVisible, setChoosePairModalVisible] = React.useState<boolean>(false);
	const [currentType, setCurrentType] = React.useState<PairType>(PairType.Contract);
	const [spotsPairs, setSpotPairs] = React.useState<string[]>([]);
	const [perpetualPairs, setPerpetualPairs] = React.useState<string[]>([]);
	const [pairItems, setPairItems] = React.useState<PairItem[]>([]);

	React.useEffect(() => {
		const changedData: RangeOfTradingTargetsParam = {
			products: description,
			leverageRanges: dataSource,
			spots: spots,
			contracts: contracts,
		};
		changeData(changedData);
	}, [dataSource, description, changeData, spots, contracts]);

	React.useEffect(() => {
		if (needCheckForm) {
			checkProducts(description)
				? setDescriptionStatus(StatusType.Normal)
				: setDescriptionStatus(StatusType.Error);
		}
	}, [needCheckForm, description]);

	React.useEffect(() => {
		getOracleRequest("/pair/usdtPairs", {}).then((res) => {
			const pairData = res as Pair[];
			let items: PairItem[] = [];
			const spots = pairData
				.filter((item) => item.type === ExchangeType.SPOT)
				.map((item) => {
					items.push({
						name: item.symbol,
						id: String(item.id),
					});
					return String(item.id);
				});
			const perpetuals = pairData
				.filter((item) => item.type === ExchangeType.PERPETUAL)
				.map((item) => {
					items.push({
						name: item.symbol,
						id: String(item.id),
					});
					return String(item.id);
				});
			setSpotPairs(spots);
			setPerpetualPairs(perpetuals);
			setPairItems(items);
		});
	}, []);

	const deleteCurrentPair = (currentPair: string, type: PairType) => {
		let pairs: string[] = [];
		if (type === PairType.Contract) {
			pairs = [...contracts];
			pairs = pairs.filter((item) => item !== currentPair);
			setContracts(pairs);
		} else {
			pairs = [...spots];
			pairs = pairs.filter((item) => item !== currentPair);
			setSpots(pairs);
		}
	};

	const getPairs = (items: string[], type: PairType) => {
		if (items.length > 1) {
			return items.map((item) => {
				return (
					<div className={styles["item"]} key={item}>
						{pairItems.filter((originItem) => originItem.id === item)[0]?.name}
						<img src={deleteIcon} onClick={() => deleteCurrentPair(item, type)} />
					</div>
				);
			});
		} else {
			return <div className={styles["item"]}>{pairItems.filter((item) => item.id === items[0])[0]?.name}</div>;
		}
	};

	const choosePairs = (type: PairType) => {
		type === PairType.Contract ? setCurrentType(PairType.Contract) : setCurrentType(PairType.Spot);
		setChoosePairModalVisible(true);
	};

	return (
		<div className={styles["trading-targets"]}>
			<div className={styles["title"]}>
				{t(keys.tradingTargets)}
				<span>{t(keys.spotUnlimited)}</span>
			</div>
			<div className={styles["content"]}>
				<div>
					<div>{t(keys.rangeDescription)}</div>
					<div>
						<TextArea
							placeholder={t(keys.pleaseInput)}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							status={descriptionStatus}
							style={{ maxHeight: 228, minHeight: 80 }}
							autoSize={true}
							showCount
							maxLength={500}
						/>
					</div>
				</div>
				<div>
					<div className={styles["chosen-item"]}>
						<div>
							<span>{t(keys.futures)}</span>
							<Button onClick={() => choosePairs(PairType.Contract)}>
								<img src={addIcon} />
								<span>{t(keys.select)}</span>
							</Button>
						</div>
						<div>{getPairs(contracts, PairType.Contract)}</div>
					</div>
					<div className={styles["chosen-item"]}>
						<div>
							<span>{t(keys.spot)}</span>
							<Button onClick={() => choosePairs(PairType.Spot)}>
								<img src={addIcon} />
								<span>{t(keys.select)}</span>
							</Button>
						</div>
						<div>{getPairs(spots, PairType.Spot)}</div>
					</div>
				</div>
			</div>
			<ChoosePairModal
				visible={choosePairModalVisible}
				closeModal={() => setChoosePairModalVisible(false)}
				chosenPairs={currentType === PairType.Contract ? contracts : spots}
				type={currentType}
				changeChosenPairs={currentType === PairType.Contract ? setContracts : setSpots}
				spotsPairs={spotsPairs}
				perpetualPairs={perpetualPairs}
				pairItems={pairItems}
			/>
		</div>
	);
};
