export const keys: { [key: string]: string } = {
	fundParadise: "fundParadise",
	fundDetails: "fundDetails",
	currentProfit: "currentProfit",
	totalCapital: "totalCapital",
	walletBalance: "walletBalance",
	deposit: "deposit",
	frozenMargin: "frozenMargin",
	availableBalance: "availableBalance",
	allHistoricalProfit: "allHistoricalProfit",
	myFunds: "myFunds",
	transactionSummary: "transactionSummary",
	quantFund: "quantFund",
	valueFund: "valueFund",
	themeFund: "themeFund",
	comingSoon: "comingSoon",
	name: "name",
	expectedApr: "expectedApr",
	apr: "apr",
	aum: "aum",
	followedInvestors: "followedInvestors",
	return: "return",
	sinceInception: "sinceInception",
	follow: "follow",
	addInvestment: "addInvestment",
	addFollow: "addFollow",
	managerSafetyNet: "managerSafetyNet",
	alreadyRealTradingVerified: "alreadyRealTradingVerified",
	alreadyBacktestingVerified: "alreadyBacktestingVerified",
	cumulativeReturn: "cumulativeReturn",
	followSetting: "followSetting",
	fund: "fund",
	capital: "capital",
	exchangeAccount: "exchangeAccount",
	importExchangeAccount: "importExchangeAccount",
	exchangeAccountBalance: "exchangeAccountBalance",
	orderPercentage: "orderPercentage",
	ratioSingleOrderAmountToCapital: "ratioSingleOrderAmountToCapital",
	positionRatioEqualsFundManagerMinAmountMoreThanTenUSDT: "positionRatioEqualsFundManagerMinAmountMoreThanTenUSDT",
	investmentPeriods: "investmentPeriods",
	range: "range",
	inputPeriods: "inputPeriods",
	followFee: "followFee",
	managementFee: "managementFee",
	commission: "commission",
	periodAt: "periodAt",
	end: "end",
	insufficientBalancePleaseDepositAsap: "insufficientBalancePleaseDepositAsap",
	pleaseImportExchangeAccountFirst: "pleaseImportExchangeAccountFirst",
	startOfPeriodFrozenMargin: "startOfPeriodFrozenMargin",
	endOfPeriodSettlement: "endOfPeriodSettlement",
	frozenMarginReleasedToFollowerAccountIfLoss: "frozenMarginReleasedToFollowerAccountIfLoss",
	oneFifthCurrentProfitAsSharedDeductedIfProfitRemainingReleased:
		"oneFifthCurrentProfitAsSharedDeductedIfProfitRemainingReleased",
	followed: "followed",
	selectAccount: "selectAccount",
	balance: "balance",
	runtime: "runtime",
	dayAt: "dayAt",
	nextSettlement: "nextSettlement",
	fundEndDate: "fundEndDate",
	realNetProfit: "realNetProfit",
	investmentReport: "investmentReport",
	fundStrategy: "fundStrategy",
	fundElements: "fundElements",
	instruction: "instruction",
	pair: "pair",
	leverageTimes: "leverageTimes",
	initialBacktestingCapital: "initialBacktestingCapital",
	positionMode: "positionMode",
	dateRange: "dateRange",
	cumulativeReturnCurve: "cumulativeReturnCurve",
	pAndL: "pAndL",
	tradeTimes: "tradeTimes",
	winRatio: "winRatio",
	fundRiskReturnCharacteristics: "fundRiskReturnCharacteristics",
	expectedDrawdown: "expectedDrawdown",
	renewalPeriod: "renewalPeriod",
	inceptionDate: "inceptionDate",
	subscriptionClosingDate: "subscriptionClosingDate",
	operationMode: "operationMode",
	openEnd: "openEnd",
	closedEnd: "closedEnd",
	incentiveFee: "incentiveFee",
	stopLossRatio: "stopLossRatio",
	tradingTargets: "tradingTargets",
	futures: "futures",
	leverage: "leverage",
	followedInvestorsTotal: "followedInvestorsTotal",
	totalProfitOfFollowedInvestors: "totalProfitOfFollowedInvestors",
	closed: "closed",
	tradingRecord: "tradingRecord",
	tradingRecordShort: "tradingRecordShort",
	stop: "stop",
	stopFollowing: "stopFollowing",
	singleCapitalAmountToAvailableAmountConsistentWithManagerAndMinimumTen:
		"singleCapitalAmountToAvailableAmountConsistentWithManagerAndMinimumTen",
	stopCopyAndOrdersStopOtherwiseExistOpenCopyingPositions: "stopCopyAndOrdersStopOtherwiseExistOpenCopyingPositions",
	settlementAfterStoppingDayFrozenMarginReleasedFollowingDay:
		"settlementAfterStoppingDayFrozenMarginReleasedFollowingDay",
	confirm: "confirm",
	cancel: "cancel",
	settled: "settled",
	current: "current",
	pending: "pending",
	marginFrozen: "marginFrozen",
	marginReleased: "marginReleased",
	safetyNetAmountRefunded: "safetyNetAmountRefunded",
	details: "details",
	time: "time",
	type: "type",
	initialFollowing: "initialFollowing",
	additionalFollowing: "additionalFollowing",
	amount: "amount",
	account: "account",
	paidAmount: "paidAmount",
	lossAmount: "lossAmount",
	crypto: "crypto",
	dealTime: "dealTime",
	direction: "direction",
	filled: "filled",
	price: "price",
	summary: "summary",
	profit: "profit",
	update: "update",
	exchange: "exchange",
	pleaseInput: "pleaseInput",
	hedgeMode: "hedgeMode",
	pleaseEnsureSameAPIKeySecretAsBefore: "pleaseEnsureSameAPIKeySecretAsBefore",
	register: "register",
	email: "email",
	verificationCode: "verificationCode",
	send: "send",
	next: "next",
	previous: "previous",
	alreadyHaveAccount: "alreadyHaveAccount",
	toLogIn: "toLogIn",
	noAccount: "noAccount",
	registerNow: "registerNow",
	passwordForgotten: "passwordForgotten",
	emailTip: "emailTip",
	emailNotRegistered: "emailNotRegistered",
	resend: "resend",
	pleaseInputValidEmailAddress: "pleaseInputValidEmailAddress",
	emailAlreadyRegistered: "emailAlreadyRegistered",
	pleaseInputCorrectVerificationCode: "pleaseInputCorrectVerificationCode",
	verificationCodeExpired: "verificationCodeExpired",
	setPassword: "setPassword",
	eightToSixteenDigitsOrLetterCaseSensitive: "eightToSixteenDigitsOrLetterCaseSensitive",
	confirmPassword: "confirmPassword",
	passwordDifferent: "passwordDifferent",
	registeredSuccess: "registeredSuccess",
	logIn: "logIn",
	user: "user",
	password: "password",
	incorrectAccountPasswordPleaseTryAgain: "incorrectAccountPasswordPleaseTryAgain",
	resetPassword: "resetPassword",
	setNewPassword: "setNewPassword",
	finished: "finished",
	success: "success",
	withdraw: "withdraw",
	logOut: "logOut",
	followTrader: "followTrader",
	fundManager: "fundManager",
	investor: "investor",
	selectWalletProtocol: "selectWalletProtocol",
	securityTip: "securityTip",
	confirmSelectedProtocol: "confirmSelectedProtocol",
	completeTransferThroughWallet: "completeTransferThroughWallet",
	copyAddress: "copyAddress",
	accumulativeDeposit: "accumulativeDeposit",
	cumulativeWithdrawal: "cumulativeWithdrawal",
	arrivalTime: "arrivalTime",
	amountReceived: "amountReceived",
	protocol: "protocol",
	reset: "reset",
	inTotal: "inTotal",
	walletAddress: "walletAddress",
	profitDetails: "profitDetails",
	investmentProfit: "investmentProfit",
	insufficientFrozenMargin: "insufficientFrozenMargin",
	insufficientFrozenMarginPleaseAdd: "insufficientFrozenMarginPleaseAdd",
	addMarginShort: "addMarginShort",
	automaticStop: "automaticStop",
	fundStoppedBecauseEstimatedFeeLargerThanFrozenMargin: "fundStoppedBecauseEstimatedFeeLargerThanFrozenMargin",
	view: "view",
	frozenFromAvailableBalanceInWallet: "frozenFromAvailableBalanceInWallet",
	oneFifthProfitDeductedAsFeesFromFrozenMarginAfterSettlement:
		"oneFifthProfitDeductedAsFeesFromFrozenMarginAfterSettlement",
	currentFund: "currentFund",
	historicalFund: "historicalFund",
	insufficientMargin: "insufficientMargin",
	addMargin: "addMargin",
	estimatedFee: "estimatedFee",
	pleaseAddFrozenMargin: "pleaseAddFrozenMargin",
	stopFollowWhenMoreThanOneHundred: "stopFollowWhenMoreThanOneHundred",
	historicalProfit: "historicalProfit",
	totalProfitAfterSettlement: "totalProfitAfterSettlement",
	unsettledProfit: "unsettledProfit",
	expectedProfit: "expectedProfit",
	ProfitAfterDeductingFeeFromUnsettledProfit: "ProfitAfterDeductingFeeFromUnsettledProfit",
	successCancel: "successCancel",
	addFrozenMargin: "addFrozenMargin",
	addAmount: "addAmount",
	inputAmount: "inputAmount",
	settlementRecord: "settlementRecord",
	settlementTime: "settlementTime",
	stopFollowingSettlement: "stopFollowingSettlement",
	fee: "fee",
	netProfit: "netProfit",
	fundPeriodicSettlement: "fundPeriodicSettlement",
	depositAndWithdrawa: "depositAndWithdrawa",
	all: "all",
	marginDeducted: "marginDeducted",
	initialFollow: "initialFollow",
	addFollowingAmount: "addFollowingAmount",
	fundEnd: "fundEnd",
	feeRate: "feeRate",
	loss: "loss",
	refundRate: "refundRate",
	submitted: "submitted",
	currentCapital: "currentCapital",
	followAccount: "followAccount",
	myWallet: "myWallet",
	start: "start",
	upTo: "upTo",
	lastSettlement: "lastSettlement",
	openLong: "openLong",
	closeLong: "closeLong",
	openShort: "openShort",
	closeShort: "closeShort",
	perpetual: "perpetual",
	spot: "spot",
	contentDetails: "contentDetails",
	capitalAmountVerify: "capitalAmountVerify",
	orderPercentageVerify: "orderPercentageVerify",
	copy: "copy",
	privacyPolicy: "privacyPolicy",
	managerDetails: "managerDetails",
	statisticsTarget: "statisticsTarget",
	allTrade: "allTrade",
	long: "long",
	short: "short",
	finalProfit: "finalProfit",
	totalProfit: "totalProfit",
	totalLoss: "totalLoss",
	profitAndLossRate: "profitAndLossRate",
	winRate: "winRate",
	profitNumber: "profitNumber",
	lossNumber: "lossNumber",
	averageNetProfit: "averageNetProfit",
	averageProfit: "averageProfit",
	averageLoss: "averageLoss",
	averageProfitAndLossRate: "averageProfitAndLossRate",
	maxProfit: "maxProfit",
	maxLoss: "maxLoss",
	maxProfitOrTotalProfit: "maxProfitOrTotalProfit",
	maxLossOrTotalLoss: "maxLossOrTotalLoss",
	netProfitAndMaxLoss: "netProfitAndMaxLoss",
	maxProfitNumber: "maxProfitNumber",
	maxLossNumber: "maxLossNumber",
	averageHoldingPeriod: "averageHoldingPeriod",
	averageProfitPeriod: "averageProfitPeriod",
	averageLossPeriod: "averageLossPeriod",
	totalTradeCost: "totalTradeCost",
	sharpeRatio: "sharpeRatio",
	totalPeriod: "totalPeriod",
	holdingPeriodOrTotalPeriod: "holdingPeriodOrTotalPeriod",
	holdingPeriod: "holdingPeriod",
	maxNonTrainingPeriod: "maxNonTrainingPeriod",
	holdingBars: "holdingBars",
	maxWithdrawalRate: "maxWithdrawalRate",
	drawdownValue: "drawdownValue",
	occurTime: "occurTime",
	maxDrawdownValue: "maxDrawdownValue",
	drawdown: "drawdown",
	allocationRisk: "allocationRisk",
	maxDrawdownWithoutLeverage: "maxDrawdownWithoutLeverage",
	liquidationTimes: "liquidationTimes",
	newFund: "newFund",
	settledSharedProfit: "settledSharedProfit",
	unsettledSharedProfit: "unsettledSharedProfit",
	frozenSafetyNet: "frozenSafetyNet",
	investorProfits: "investorProfits",
	totalSharedProfit: "totalSharedProfit",
	state: "state",
	open: "open",
	expire: "expire",
	profitDetail: "profitDetail",
	alreadySettled: "alreadySettled",
	investorHistoricalProfit: "investorHistoricalProfit",
	managerSettledSharedProfit: "managerSettledSharedProfit",
	unsettled: "unsettled",
	managerExpectedSharedProfit: "managerExpectedSharedProfit",
	sharedProfitRatio: "sharedProfitRatio",
	settleTime: "settleTime",
	fundRange: "fundRange",
	investorProfit: "investorProfit",
	cisSharedProfit: "cisSharedProfit",
	managerSharedProfit: "managerSharedProfit",
	cisExpectedSharedProfit: "cisExpectedSharedProfit",
	referenceStrategyAndInvestmentReport: "referenceStrategyAndInvestmentReport",
	basicInformation: "basicInformation",
	managerAccountRule: "managerAccountRule",
	investorPlaceOrderRule: "investorPlaceOrderRule",
	expirationDate: "expirationDate",
	settledPeriod: "settledPeriod",
	month: "month",
	quarter: "quarter",
	sixMonths: "sixMonths",
	year: "year",
	cipFundAccount: "cipFundAccount",
	followMode: "followMode",
	fullFollowRatio: "fullFollowRatio",
	customRatio: "customRatio",
	fullFollowRatioTooltip: "fullFollowRatioTooltip",
	customRatioTooltip: "customRatioTooltip",
	returnAndRiskFactors: "returnAndRiskFactors",
	estimatedMaxDrawdown: "estimatedMaxDrawdown",
	stopLossLine: "stopLossLine",
	safetyNetAmoun: "safetyNetAmoun",
	optional: "optional",
	tradingTargetRange: "tradingTargetRange",
	spotUnlimited: "spotUnlimited",
	rangeDescription: "rangeDescription",
	leverageRange: "leverageRange",
	add: "add",
	minLeverage: "minLeverage",
	maxLeverage: "maxLeverage",
	selectPair: "selectPair",
	referenceStrategy: "referenceStrategy",
	referenceReport: "referenceReport",
	publish: "publish",
	publishDone: "publishDone",
	networkErrorTip: "networkErrorTip",
	nameOccupied: "nameOccupied",
	fundManagement: "fundManagement",
	digitsLettersUnderscores: "digitsLettersUnderscores",
	startDate: "startDate",
	finishDate: "finishDate",
	alias: "alias",
	nameGeneral: "nameGeneral",
	period: "period",
	lever: "lever",
	maxDrawdown: "maxDrawdown",
	fixedPosition: "fixedPosition",
	dynamicPosition: "dynamicPosition",
	strategyPosition: "strategyPosition",
	referenceStrategyAndInvestmentReportVerify: "referenceStrategyAndInvestmentReportVerify",
	apiKeyAndSecretVerify: "apiKeyAndSecretVerify",
	noData: "noData",
	apiKeyVerify: "apiKeyVerify",
	sinceIncep: "sinceIncep",
	uncheckDefaultSecurityControlsInBinanceWebAPIManagement: "uncheckDefaultSecurityControlsInBinanceWebAPIManagement",
	importedTradingAccountMustBeHedgeMode: "importedTradingAccountMustBeHedgeMode",
	exchangeAccountInTradingModeAndCannotBeUpdated: "exchangeAccountInTradingModeAndCannotBeUpdated",
	updated: "updated",
	pleaseEnsureNewApiKeySecretFromSameExchangeAccountAsBefore:
		"pleaseEnsureNewApiKeySecretFromSameExchangeAccountAsBefore",
	failedTooManyTimesPleaseTryAgainTomorrow: "failedTooManyTimesPleaseTryAgainTomorrow",
	select: "select",
	selected: "selected",
	fundManagerStrategyBank: "fundManagerStrategyBank",
	notifications: "notifications",
	invalid: "invalid",
	allDeal: "allDeal",
	successful: "successful",
	reason: "reason",
	submittedPleaseCheckInHistoricalFunds: "submittedPleaseCheckInHistoricalFunds",
	stopping: "stopping",
	failed: "failed",
	closureFailurePleaseUpdateYourApiKey: "closureFailurePleaseUpdateYourApiKey",
	ifUpdatedPleaseWaitForSystemAutomaticallyClosePosition: "ifUpdatedPleaseWaitForSystemAutomaticallyClosePosition",
	cisInvestmentAgreement: "cisInvestmentAgreement",
	agree: "agree",
	loading: "loading",
	loadingFailedPleasePullDownOrClickReload: "loadingFailedPleasePullDownOrClickReload",
	noMoreData: "noMoreData",
	copied: "copied",
	insufficientFundFrozenMargin: "insufficientFundFrozenMargin",
	failedTag: "failedTag",
	reload: "reload",
	backtestingReturn: "backtestingReturn",
	annualizedReturn: "annualizedReturn",
	sort: "sort",
	filter: "filter",
	realTradingVerified: "realTradingVerified",
	backtestingVerified: "backtestingVerified",
	followingFailedToast: "followingFailedToast",
	automaticallyStoppedPopover: "automaticallyStoppedPopover",
	agreementToTheTermsOfService: "agreementToTheTermsOfService",
	termsOfService: "termsOfService",
	selectAtLeastOneOption: "selectAtLeastOneOption",
	Refunded: "Refunded",
	capitalLossAmount: "capitalLossAmount",
};
export type TranslationKeys = typeof keys;
export type KeyOfTranslationKeys = keyof TranslationKeys;
