import dayjs from "dayjs";
import { Accounts } from "../typings/accounts";
import { RangePickerProps } from "antd/es/date-picker";

export function useFormConfig(fundAccount: string, accounts: Accounts[]) {
	const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
		return (
			(current && current > dayjs().endOf("day")) ||
			current <= dayjs(getAccountFirstTradingTime(fundAccount, accounts))
		);
	};
	const options = accounts.map((account) => ({ label: account.accountName, value: account.accountId }));
	return { options, disabledDate };
}

export const getAccountFirstTradingTime = (accountId: string, accounts: Accounts[]) => {
	return accounts.find((account) => account.accountId === accountId)?.firstTradingTime;
};
