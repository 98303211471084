import styles from './DetailCards.less';
import useLanguage from '@shared/hooks/useLanguage';

export default function DetailCards() {
	const {t, keys} = useLanguage();

	return (
		<>
			<div className={styles["settled"]}>
				<div className={styles["card_title"]}>{t(keys.settled)}</div>
				<div className={styles["profit"]}>
					<div className={styles["profit_text"]}>{t(keys.investorHistoricalProfit)}</div>
					<div className={styles["profit_number"]}>1111111</div>
					<div className={styles["USDT"]}>USDT</div>
				</div>
				<div className={styles["profit"]}>
					<div className={styles["profit_text"]}>{t(keys.managerSettledSharedProfit)}</div>
					<div className={styles["profit_number"]}>1111111</div>
					<div className={styles["USDT"]}> USDT</div>
				</div>
			</div>
			<div className={styles["unsettled"]}>
				<div className={styles["card_title"]}>{t(keys.unsettled)}</div>
				<div className={styles["profit"]}>
					<div className={styles["profit_text"]}>Investor Expected Profit</div>
					<div className={styles["profit_number"]}>3</div>
					<div className={styles["USDT"]}>USDT</div>
				</div>
				<div className={styles["profit"]}>
					<div className={styles["profit_text"]}>{t(keys.managerExpectedSharedProfit)}</div>
					<div className={styles["profit_number"]}>1111111</div>
					<div className={styles["USDT"]}>USDT</div>
				</div>
			</div>
			<div className={styles["ratio"]}>
				<div>{t(keys.sharedProfitRatio)}</div>
				<div>16</div>
				<div>%</div>
			</div>
		</>
	);
}