import { useEffect } from "react";
import { FundFactorParams } from "../typings/fundFactor";
import { Key } from "antd/es/table/interface";
import { ReferenceInvestmentReport } from "../typings/importInvestmentReport";
import { postNewFund } from "../service/createFund";
import { useNavigate } from "react-router-dom";
import { useCreateFundNotice } from "./investmentFormHooks";
import { composeNewFund } from "../utils/newFundGenerator";
import { getInit } from "../utils/createFundController";

export const useCreateFund = (
	submit: boolean,
	validate: boolean,
	fundFactorParams: FundFactorParams,
	referenceStrategies: Key[],
	referenceInvestmentReport: ReferenceInvestmentReport | undefined,
	hasReferenceStrategies: boolean,
	hasReferenceInvestmentReport: boolean,
	referenceStrategiesInit: boolean,
	referenceInvReportInit: boolean,
	setReferenceStrategiesInit: (val: boolean) => void,
	setReferenceInvReportInit: (val: boolean) => void,
	setSubmit: (val: boolean) => void,
	setValidate: (val: boolean) => void
) => {
	const navigate = useNavigate();
	const { createFundNotice } = useCreateFundNotice();
	useEffect(() => {
		const init = getInit(
			hasReferenceStrategies,
			hasReferenceInvestmentReport,
			referenceStrategiesInit,
			referenceInvReportInit
		);
		const newFund = composeNewFund(fundFactorParams, referenceStrategies, referenceInvestmentReport);
		if (submit && validate && init) {
			postNewFund(newFund, createFundNotice).then((res) => {
				navigate("fundManagement/myfund");
				setSubmit(false);
				setValidate(false);
				setReferenceStrategiesInit(false);
				setReferenceInvReportInit(false);
			});
		}
	}, [
		createFundNotice,
		fundFactorParams,
		fundFactorParams.baseInformation,
		fundFactorParams.benefitAndRiskFactors,
		fundFactorParams.rangeOfTradingTargets,
		hasReferenceInvestmentReport,
		hasReferenceStrategies,
		navigate,
		referenceInvReportInit,
		referenceInvestmentReport,
		referenceStrategies,
		referenceStrategiesInit,
		setReferenceInvReportInit,
		setReferenceStrategiesInit,
		setSubmit,
		setValidate,
		submit,
		validate,
	]);
};

