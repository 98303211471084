import React from "react";
import CreateAccount from "./CreateAccount";

interface Props {
	isResetingPwd: boolean;
}

export default function VerifyIdentity(props: Props) {

	return props.isResetingPwd ?
		<CreateAccount isResetingPwd={props.isResetingPwd} /> : <CreateAccount />;
}