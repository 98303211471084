import { Key, useEffect } from "react";
import { getAccountsList, getReportSummary } from "../service/referenceInvestmentReport";
import { Accounts } from "../typings/accounts";
import { ReferenceInvestmentReport, ReportSummary } from "../typings/importInvestmentReport";
import { genPeriodAccumulativeReturn } from "../utils/accumulativeChartGenerator";
import { SubmitValidateResult, submitValidate } from "../utils/investmentReportFormValidator";
import { ValidateStatus } from "antd/es/form/FormItem";
import { message } from "antd";
import useLanguage from "@shared/hooks/useLanguage";
import { ResponseResult } from "@shared/utils/request";

export const useUpdateAccounts = (setAccounts: (val: Accounts[]) => void) => {
	useEffect(() => {
		const fetchAccounts = async () => {
			const res = ((await getAccountsList()) as Accounts[]) || [];
			setAccounts(res);
		};
		fetchAccounts();
	}, [setAccounts]);
};
export const useUpdateInvestmentReport = (
	start: number,
	end: number,
	fundAccount: string,
	valueChange: boolean,
	setValueChange: (val: boolean) => void,
	setInvReportSummary: (val: ReportSummary) => void,
	setPeriodAccumulativeReturn: (val: (string | number)[][]) => void
) => {
	useEffect(() => {
		const init = fundAccount && start && end;
		const fetchReportSummary = async () => {
			const params = { start, end, account: fundAccount };
			const res = ((await getReportSummary(params)) as ReportSummary) || {};
			setInvReportSummary(res);
			setPeriodAccumulativeReturn(genPeriodAccumulativeReturn(res));
		};
		init && valueChange && fetchReportSummary();
		setValueChange(false);
	}, [end, fundAccount, setInvReportSummary, setPeriodAccumulativeReturn, setValueChange, start, valueChange]);
};

export const useSubmit = (
	submit: boolean,
	setSubmit: (val: boolean) => void,
	fundAccount: string,
	start: number,
	end: number,
	referenceStrategies: Key[],
	setHasReferenceStrategies: (val: boolean) => void,
	setHasReferenceInvestmentReport: (val: boolean) => void,
	setValidate: (val: boolean) => void,
	startValidateStatus: ValidateStatus,
	endValidateStatus: ValidateStatus
) => {
	const { t, keys } = useLanguage();
	useEffect(() => {
		if (submit) {
			setHasReferenceStrategies(referenceStrategies.length > 0);
			setHasReferenceInvestmentReport(!!(fundAccount && start && end));
			const result = submitValidate(
				fundAccount,
				start,
				end,
				referenceStrategies,
				setValidate,
				startValidateStatus,
				endValidateStatus
			);
			switch (result) {
				case SubmitValidateResult.Success:
					// We don't inform users of 'success' until postRequest is success.
					break;
				case SubmitValidateResult.Lack:
					message.error(t(keys.referenceStrategyAndInvestmentReportVerify));
					setSubmit(false);
					break;
				default:
					break;
			}
		}
	}, [
		end,
		endValidateStatus,
		fundAccount,
		keys.referenceStrategyAndInvestmentReportVerify,
		keys.success,
		referenceStrategies,
		setHasReferenceInvestmentReport,
		setHasReferenceStrategies,
		setSubmit,
		setValidate,
		start,
		startValidateStatus,
		submit,
		t,
	]);
};

export const useCreateFundNotice = () => {
	const { t, keys } = useLanguage();
	const createFundNotice = (result: ResponseResult) => {
		if (result === ResponseResult.Success) {
			message.success(t(keys.publishDone));
		} else {
			message.error(t(keys.networkErrorTip));
		}
	};
	return { createFundNotice };
};

export const useComposeInvestmentReportParams = (
	validate: boolean,
	setReferenceInvReportInit: (val: boolean) => void,
	fundAccount: string,
	start: number,
	end: number,
	invReportSummary: ReportSummary | undefined,
	setReferenceInvestmentReport: (val: ReferenceInvestmentReport) => void
) => {
	useEffect(() => {
		if (validate && invReportSummary) {
			setReferenceInvestmentReport({
				fundAccount,
				start,
				end,
				tradeCount: invReportSummary.tradeCount,
				winRate: invReportSummary.winRate,
				cumulativeReturn: invReportSummary.cumulativeReturn,
				annualizedReturn: invReportSummary.annualizedReturn,
			});
			setReferenceInvReportInit(true);
		}
	}, [end, fundAccount, invReportSummary, setReferenceInvReportInit, setReferenceInvestmentReport, start, validate]);
};