import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import styles from "./ProfitDetails.less";
import DetailTable from './DetailTable';
import DetailCards from './DetailCards';
import detailsIcon from "@shared/assets/png/details.png";
import useLanguage from '@shared/hooks/useLanguage';
import { TranslationKeys } from '@shared/i18next/languages/keys';
import { TFunction } from 'i18next';

export default function ProfitDetails() {
	const { t, keys } = useLanguage();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const menus = getMenu(t, keys);

	const showModal = () => {
	  	setIsModalOpen(true);
	};

	const handleCancel = () => {
	  	setIsModalOpen(false);
	};

	return (
		<>
			<div onClick={showModal} className={styles["icon"]}>
				<img src={detailsIcon} />
			</div>
			<Modal
				title="Profit Details"
				open={isModalOpen}
				onCancel={handleCancel}
				wrapClassName={styles["modal-container"]}
				footer={[]}
			>
				<div className={styles["text"]}>
					<DetailCards />
				</div>
				<div className={styles["table"]}>
					<Tabs defaultActiveKey="settled" items={menus} />
				</div>
			</Modal>
		</>
	);
}

function getMenu(t: TFunction<"translation", undefined>, keys: TranslationKeys) {

	return [
		{
			key: "settled",
			label: t(keys.settled),
			children: <DetailTable isSettled={true} />,
		},
		{
			key: "unsettled",
			label: t(keys.unsettled),
			children: <DetailTable isSettled={false} />,
		}
	];
}