import { ResponseResult, postFundRequest } from "@shared/utils/request";
import { NewFund } from "../typings/createFund";
import {
	BaseInformation,
	BenefitAndRiskFactorsParam,
	FundFactorParams,
	LeverageRanges,
	RangeOfTradingTargetsParam,
} from "../typings/fundFactor";
import { validateFundName } from "../utils/fundFactor";

export const postNewFund = (newFund: NewFund, fn: (val: ResponseResult) => void) => {
	return postFundRequest("fund", newFund, fn);
};

export const formatInputNumber = (num: string) => {
	let result = num;
	//先把非数字的都替换掉，除了数字和小数点
	result = result.replace(/[^\d.-]/g, "");
	//必须保证第一个为数字而不是小数点
	result = result.replace(/^\./g, "");
	//保证只有出现一个小数点而没有多个小数点
	result = result.replace(/\.{2,}/g, ".");
	//保证小数点只出现一次，而不能出现两次以上
	result = result.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
	//保证只能输入两个小数
	result = result.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
	return result;
};

const checkBaseInformationData = (baseInformation: BaseInformation) => {
	const { name, startDate, endDate, fundAccount, isNameExist } = baseInformation;

	return !(!validateFundName(name) || startDate === 0 || endDate === 0 || fundAccount === "" || isNameExist);
};

export const checkExpectedAnnualizedReturn = (annualizedReturn: number) => {
	return annualizedReturn > 0;
};

export const checkExpectedMaximumWithdrawalRate = (maximumWithdrawalRate: number) => {
	return maximumWithdrawalRate <= 0 && maximumWithdrawalRate >= -100;
};

export const checkStopLossRatio = (ratio: number) => {
	return ratio <= 0 && ratio >= -100;
};

export const checkSafetyNetAmount = (amount: number) => {
	return amount >= 100 || amount === 0;
};

const checkBenefitAndRiskFactorsParamData = (benefitAndRiskFactorsParamData: BenefitAndRiskFactorsParam) => {
	const { expectedAnnualizedReturn, expectedMaximumWithdrawalRate, stopLossRatio, safetyNetAmount } =
		benefitAndRiskFactorsParamData;
	return !(
		!checkExpectedAnnualizedReturn(expectedAnnualizedReturn) ||
		!checkExpectedMaximumWithdrawalRate(expectedMaximumWithdrawalRate) ||
		!checkStopLossRatio(stopLossRatio) ||
		!checkSafetyNetAmount(safetyNetAmount)
	);
};

export const checkProducts = (products: string) => {
	return products !== "";
};

export const checkLeverageRangesData = (leverageRanges: LeverageRanges[]) => {
	const isLeverageRangeCurrect = (item: LeverageRanges) => {
		const { pair, max, min } = item;
		return !(pair === "" || max === "" || min === "" || Number(max) < Number(min));
	};
	return leverageRanges.every(isLeverageRangeCurrect);
};

const checkRangeOfTradingTargetsParamData = (params: RangeOfTradingTargetsParam) => {
	const { products } = params;

	return !!checkProducts(products);
};

export const canFundFactorParamsSunbmit = (fundFactorParams: FundFactorParams) => {
	const { baseInformation, benefitAndRiskFactors, rangeOfTradingTargets } = fundFactorParams;

	return (
		checkBaseInformationData(baseInformation) &&
		checkBenefitAndRiskFactorsParamData(benefitAndRiskFactors) &&
		checkRangeOfTradingTargetsParamData(rangeOfTradingTargets)
	);
};
