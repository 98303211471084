import React from "react";
import styles from "./ImportStrategy.less";
import { Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { StrategiesTable } from "@/manager/typings/importStrategies";
import { formatAssetsNum } from "@shared/utils/numberUtils";
import { PositionType, PositionTypeEnum } from "@/manager/utils/enumGenerator";
import useLanguage from "@shared/hooks/useLanguage";
import { formatTime } from "@shared/utils/dateUtils";
import { Key } from "antd/es/table/interface";
import { useGetPairs, useUpdateStrategies } from "@/manager/hooks/importStartegiesServiceHooks";
import { getTableConfig } from "@/manager/utils/tableConfigManagement";
import { Pair } from "@shared/enum/enum";

interface Props {
	setReferenceStrategies: (val: Key[]) => void;
	setReferenceStrategiesInit: (val: boolean) => void;
}
export default function ImportStrategy({ setReferenceStrategies, setReferenceStrategiesInit }: Props) {
	const [strategies, setStrategies] = React.useState<StrategiesTable[]>();
	const [pairs, setPairs] = React.useState<Pair[]>([]);
	const { t, keys } = useLanguage();
	const columns = useColumns(pairs);
	const rowSelection = getTableConfig(setReferenceStrategies, setReferenceStrategiesInit);
	useUpdateStrategies(setStrategies);
	useGetPairs(setPairs);

	return (
		<div className={styles["strategy-container"]}>
			<div className={styles["strategy-header"]}>{t(keys.referenceStrategy)}</div>
			{strategies ? (
				<Table
					rowSelection={{
						type: "checkbox",
						...rowSelection,
					}}
					scroll={{ x: 1000 }}
					rowKey={(record) => record.strategyId}
					columns={columns}
					dataSource={strategies}
					pagination={{
						pageSize: 8,
					}}
				/>
			) : (
				<Spin />
			)}
		</div>
	);
}

function useColumns(pairs: Pair[]) {
	const { t, keys } = useLanguage();
	const columns: ColumnsType<StrategiesTable> = [
		{
			title: t(keys.nameGeneral),
			dataIndex: "strategyName",
			key: "strategyName",
			render: (text) => <div className={styles["strategyName"]}>{text}</div>,
			align: "left",
		},
		{
			title: t(keys.alias),
			dataIndex: "strategyNick",
			key: "strategyNick",
			render: (text) => <div className={styles["strategyNick"]}>{text}</div>,
		},
		{
			title: t(keys.pair),
			dataIndex: "pair",
			key: "pair",
			render: (text) => (
				<div className={styles["pair"]}>
					{pairs.length > 0 ? pairs.find((i) => i.id.toString() === text)?.symbol : "--"}
				</div>
			),
		},
		{
			title: t(keys.period),
			dataIndex: "interval",
			key: "interval",
			render: (text) => <div className={styles["interval"]}>{text}</div>,
		},
		{
			title: t(keys.lever),
			dataIndex: "leverage",
			key: "leverage",
			render: (text) => (
				<div className={styles["leverage"]}>
					{text}
					{"X"}
				</div>
			),
		},
		{
			title: t(keys.initialBacktestingCapital),
			dataIndex: "initialCapital",
			key: "initialCapital",
			render: (num) => <div className={styles["initialCapital"]}>{formatAssetsNum(num)}</div>,
		},
		{
			title: t(keys.positionMode),
			dataIndex: "positionType",
			key: "positionType",
			render: (text: PositionTypeEnum, record) => (
				<div className={styles["positionType"]}>
					{t(keys[PositionType.getDescFromValue(text)])}&nbsp;
					{record.positionRate * 100 + "%"}
				</div>
			),
		},
		{
			title: t(keys.dateRange),
			dataIndex: "startTime",
			key: "startTime",
			render: (startTime, record) => (
				<div className={styles["timeRange"]}>
					{formatTime(startTime)}—{formatTime(record.endTime)}
				</div>
			),
		},
		{
			title: t(keys.tradeTimes),
			dataIndex: "tradeNumber",
			key: "tradeNumber",
			render: (text) => <div className={styles["tradeNumber"]}>{text || "--"}</div>,
		},
		{
			title: t(keys.cumulativeReturn),
			dataIndex: "cumulativeReturn",
			key: "cumulativeReturn",
			render: (text) => <div className={styles["cumulativeReturn"]}>{text || "--"}</div>,
		},
		{
			title: t(keys.maxDrawdown),
			dataIndex: "maxDrawDownRate",
			key: "maxDrawDownRate",
			render: (text) => <div className={styles["maxDrawDownRate"]}>{text || "--"}</div>,
		},
		{
			title: t(keys.profitAndLossRate),
			dataIndex: "averageProfitLossRatio",
			key: "averageProfitLossRatio",
			render: (text) => <div className={styles["averageProfitLossRatio"]}>{text || "--"}</div>,
		},
		{
			title: t(keys.apr),
			dataIndex: "annualizedReturn",
			key: "annualizedReturn",
			render: (text) => <div className={styles["annualizedReturn"]}>{text || "--"}</div>,
		},
	];
	return columns;
}
