import moment from "moment";

export const formatTime = (time: number) => {
	return moment(time).format("YYYY-MM-DD");
};

export const startMillsOfDay = (timestamp: number) => {
	let mills = timestamp % (3600 * 24 * 1000);
	return timestamp - mills + 60 * new Date().getTimezoneOffset() * 1000;
};

export const checkTimeIs0Clock = (time: number) => {
	return time % (3600 * 1000 * 24) === 0;
};

export const endMillsOfDay = (timestamp: number) => {
	const lastMillisecond = 3600 * 24 * 1000 - 1;
	let mills = timestamp % (3600 * 24 * 1000);
	return timestamp - mills + 60 * new Date().getTimezoneOffset() * 1000 + lastMillisecond;
};

export const timezoneValue = new Date().getTimezoneOffset() * 60 * 1000;