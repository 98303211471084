import createEnum from "@shared/enum/enum";

export enum PositionTypeEnum {
	// 固定仓位
	Fixed = "FIXED",
	// 动态仓位
	Dynamic = "DYNAMIC",
	// 策略仓位
	Strategy = "STRATEGY",
}
export const PositionType = createEnum({
	// 固定仓位
	Fixed: ["FIXED", "fixedPosition"],
	// 动态仓位
	Dynamic: ["DYNAMIC", "dynamicPosition"],
	// 策略仓位
	Strategy: ["STRATEGY", "strategyPosition"],
});
