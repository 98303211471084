import React from "react";
import styles from "./Login.less";
import LoginEntrance from "./LoginEntrance";
import VerifyIdentity from "./VerifyIdentity";
import loginIcon from "src/shared/assets/png/loginIcon.png";
import useLanguage from "@shared/hooks/useLanguage";

export default function Login() {
	const { t, keys } = useLanguage();
	const [hasAccount, setHasAccount] = React.useState(true);
	const [isResetingPwd, setIsResetingPwd] = React.useState(false);

	const switchLoginInterface = () => {
		setIsResetingPwd(false);
		setHasAccount(!hasAccount);
	};

	return (
		<div className={styles["login"]}>
			<div className={styles["logo"]}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div className={styles["login-content"]}>
				<div className={styles["icon"]}>
					<img src={loginIcon} style={{ width: "779px", height: "485px" }} />
				</div>
				{hasAccount ?
					<div className={styles["content"]}>
						<LoginEntrance setHasAccount={setHasAccount} setIsResetingPwd={setIsResetingPwd} />
						<div className={styles["summary"]}>
							<span className={styles["summary-left"]}>{t(keys.noAccount)}? </span>
							<span className={styles["summary-right"]} onClick={switchLoginInterface}>{t(keys.registerNow)}</span>
						</div>
					</div>
					: <div className={styles["userInformation-content"]}>
						<VerifyIdentity isResetingPwd={isResetingPwd} />
						<div className={styles["summary"]}>
							<span className={styles["summary-left"]}>{t(keys.alreadyHaveAccount)}? </span>
							<span className={styles["summary-right"]} onClick={switchLoginInterface}>{t(keys.toLogIn)}</span>
						</div>
					</div>
				}
			</div>
		</div>
	);
}