import React from "react";
import styles from "./PageBottom.less";
import emailIcon from "src/shared/assets/png/emailIcon.png";
import twitterIcon from "src/shared/assets/png/twitterIcon.png";
import wwwIcon from "src/shared/assets/png/wwwIcon.png";
import mediumIcon from "src/shared/assets/png/mediumIcon.png";

export default function PageBottom() {
	return (
		<div className={styles["page-bottom"]}>
			<div className={styles["bottom-left"]}>
				<span>Privacy Policy</span>&nbsp;&nbsp;&nbsp;&nbsp;
				<span>©2023 CIS.ASKCHAIN.IO</span>
			</div>
			<div className={styles["bottom-right"]}>
				<img src={emailIcon} />
				<span>Service@ASKCHAIN.io</span>
				<a href="https://twitter.com/ASKCHAIN_SG">
					<img src={twitterIcon} />
				</a>
				<a href="www.askchain.io">
					<img src={wwwIcon} />
				</a>
				<a href="https://medium.com/@cip_quant">
					<img src={mediumIcon} />
				</a>
			</div>
		</div>
	);
}
