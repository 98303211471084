export const zh_CN = {
	fundParadise: "基金广场",
	fundDetails: "基金详情",
	currentProfit: "当期收益",
	totalCapital: "跟单总金额",
	walletBalance: "钱包余额",
	deposit: "充值",
	frozenMargin: "冻结保证金",
	availableBalance: "可用余额",
	allHistoricalProfit: "历史总收益",
	myFunds: "我的基金",
	transactionSummary: "账单明细",
	quantFund: "量化基金",
	valueFund: "价值基金",
	themeFund: "主题基金",
	comingSoon: "即将上线",
	name: "基金名称",
	expectedApr: "预期年化收益率",
	apr: "年化收益率",
	aum: "规模",
	followedInvestors: "跟单人数",
	return: "实盘收益率",
	sinceInception: "成立以来",
	follow: "跟单",
	addInvestment: "追加跟单",
	addFollow: "追加",
	managerSafetyNet: "基金经理兜底",
	alreadyRealTradingVerified: "∬ 月实盘验证",
	alreadyBacktestingVerified: "∬ 月后验验证",
	cumulativeReturn: "累计收益率",
	followSetting: "跟单设置",
	fund: "基金",
	capital: "跟单金额",
	exchangeAccount: "交易所账户",
	importExchangeAccount: "导入交易所账户",
	exchangeAccountBalance: "交易所账户余额",
	orderPercentage: "下单比例",
	ratioSingleOrderAmountToCapital: "单笔下单金额占跟单金额比例",
	positionRatioEqualsFundManagerMinAmountMoreThanTenUSDT: "仓位比例与基金经理一致，最小为10USDT",
	investmentPeriods: "跟单期数",
	range: "期数范围",
	inputPeriods: "输入跟单期数",
	followFee: "跟单资费",
	managementFee: "投资管理费",
	commission: "交易佣金",
	periodAt: "第 ∬ 期",
	end: "结束",
	insufficientBalancePleaseDepositAsap: "可用余额不足，请尽快充值",
	pleaseImportExchangeAccountFirst: "请先导入交易所账户",
	startOfPeriodFrozenMargin: "期初预支付冻结保证金",
	endOfPeriodSettlement: "期末结算",
	frozenMarginReleasedToFollowerAccountIfLoss: "若亏损，释放冻结保证金至跟单者CIS账户",
	oneFifthCurrentProfitAsSharedDeductedIfProfitRemainingReleased: "若盈利，按照实际当期收益的20%作为跟单实际佣金从冻结保证金中扣除；剩余冻结保证金解冻释放至CIS账户",
	followed: "已跟单",
	selectAccount: "选择跟单账户",
	balance: "余额",
	runtime: "成立时间",
	dayAt: " ∬ 天",
	nextSettlement: "下一结算日",
	fundEndDate: "基金到期日",
	realNetProfit: "实盘净利润",
	investmentReport: "投资报告",
	fundStrategy: "基金策略",
	fundElements: "基金要素",
	instruction: "跟单说明",
	pair: "交易对",
	leverageTimes: "杠杆倍数",
	initialBacktestingCapital: "后验初始资金",
	positionMode: "仓位模式",
	dateRange: "日期范围",
	cumulativeReturnCurve: "累计收益率折线图",
	pAndL: "盈亏曲线图",
	tradeTimes: "交易次数",
	winRatio: "胜率",
	fundRiskReturnCharacteristics: "基金风险收益特征",
	expectedDrawdown: "预期最大回撤率",
	renewalPeriod: "续存期限",
	inceptionDate: "成立日",
	subscriptionClosingDate: "募集结束日",
	operationMode: "运作方式",
	openEnd: "开放式",
	closedEnd: "封闭式",
	incentiveFee: "业绩报酬",
	stopLossRatio: "止损比例",
	tradingTargets: "交易标的范围",
	futures: "合约",
	leverage: "杠杆范围",
	followedInvestorsTotal: "跟单总人次",
	totalProfitOfFollowedInvestors: "跟单者总收益",
	closed: "已终止",
	tradingRecord: "交易记录",
	tradingRecordShort: "交易记录",
	stop: "取消跟单",
	stopFollowing: "取消跟单",
	singleCapitalAmountToAvailableAmountConsistentWithManagerAndMinimumTen: "单笔下单金额占跟单金额中可用金额比例 与基金经理仓位占比一致，最小为10U",
	stopCopyAndOrdersStopOtherwiseExistOpenCopyingPositions: "取消跟单后，将立即停止跟随下单，若存在跟单开仓的仓位，将自动市价平仓",
	settlementAfterStoppingDayFrozenMarginReleasedFollowingDay: "取消跟单后立即结算；已冻结的保证金立即释放",
	confirm: "确定",
	cancel: "取消",
	settled: "已结算",
	current: "当期",
	pending: "未开始",
	marginFrozen: "保证金冻结",
	marginReleased: "保证金释放",
	safetyNetAmountRefunded: "兜底金额返现",
	details: "详情",
	time: "时间",
	type: "类型",
	initialFollowing: "首次跟单",
	additionalFollowing: "追加跟单",
	amount: "金额",
	account: "账户",
	paidAmount: "实付资费",
	lossAmount: "亏损金额",
	crypto: "商品",
	dealTime: "成交时间",
	direction: "方向",
	filled: "成交数量",
	price: "成交价格",
	summary: "汇总",
	profit: "收益",
	update: "更新",
	exchange: "交易所",
	pleaseInput: "请输入",
	hedgeMode: "双向持仓",
	pleaseEnsureSameAPIKeySecretAsBefore: "请确保新的API Key/Secret与之前导入来自同一交易所账户",
	register: "注册",
	email: "邮箱",
	verificationCode: "验证码",
	send: "获取验证码",
	next: "下一步",
	previous: "上一步",
	alreadyHaveAccount: "已有账号",
	toLogIn: "前往登录",
	noAccount: "没有账号",
	registerNow: "前往注册",
	passwordForgotten: "忘记密码",
	emailTip: "邮箱将作为CIS登录账号",
	emailNotRegistered: "邮箱未注册",
	resend: "重发",
	pleaseInputValidEmailAddress: "请输入合法的邮箱地址",
	emailAlreadyRegistered: "邮箱已注册",
	pleaseInputCorrectVerificationCode: "请正确输入验证码",
	verificationCodeExpired: "验证码已过期",
	setPassword: "设置密码",
	eightToSixteenDigitsOrLetterCaseSensitive: "8 - 16位数字、字母组合，区分大小写",
	confirmPassword: "确认密码",
	passwordDifferent: "密码不一致",
	registeredSuccess: "注册成功",
	logIn: "登录",
	user: "账号",
	password: "密码",
	incorrectAccountPasswordPleaseTryAgain: "账号或密码错误，请重新输入",
	resetPassword: "重置密码",
	setNewPassword: "设置新密码",
	finished: "完成",
	success: "成功",
	withdraw: "提现",
	logOut: "退出",
	followTrader: "跟单用户",
	fundManager: "基金经理",
	investor: "跟单用户",
	selectWalletProtocol: "选择钱包协议",
	securityTip: "安全提示",
	confirmSelectedProtocol: "请务必确认已选协议",
	completeTransferThroughWallet: "使用钱包APP扫码或复制下方钱包地址完成转账",
	copyAddress: "复制地址",
	accumulativeDeposit: "累计充值到账",
	cumulativeWithdrawal: "累计提现到账",
	arrivalTime: "到账时间",
	amountReceived: "到账金额",
	protocol: "协议",
	reset: "重置",
	inTotal: "共 ∬ 项",
	walletAddress: "钱包地址",
	profitDetails: "收益明细",
	investmentProfit: "投资收益",
	insufficientFrozenMargin: "冻结保证金不足",
	insufficientFrozenMarginPleaseAdd: "冻结保证金不足，请尽快补充",
	addMarginShort: "补充保证金",
	automaticStop: "自动停止跟单",
	fundStoppedBecauseEstimatedFeeLargerThanFrozenMargin: "基金 ∬ 由于预计资费超过冻结保证金，已自动停止跟单",
	view: "查看",
	frozenFromAvailableBalanceInWallet: "从CIS钱包的可用余额中冻结",
	oneFifthProfitDeductedAsFeesFromFrozenMarginAfterSettlement: "基金结算后，将实际收益的20%作为资费从冻结保证金中扣除",
	currentFund: "当前基金",
	historicalFund: "历史基金",
	insufficientMargin: "不足",
	addMargin: "补充保证金",
	estimatedFee: "预计资费",
	pleaseAddFrozenMargin: "请尽快补充冻结保证金",
	stopFollowWhenMoreThanOneHundred: "当 ≥ 100%时将自动停止跟单",
	historicalProfit: "历史收益",
	totalProfitAfterSettlement: "每次结算后收益合计",
	unsettledProfit: "待结算收益",
	expectedProfit: "预期收益",
	ProfitAfterDeductingFeeFromUnsettledProfit: "待结算收益扣除资费后收益",
	successCancel: "已取消跟单",
	addFrozenMargin: "补充冻结保证金",
	addAmount: "补充金额",
	inputAmount: "请输入补充金额",
	settlementRecord: "结算记录",
	settlementTime: "结算时间",
	stopFollowingSettlement: "停止跟单结算",
	fee: "资费",
	netProfit: "净收益",
	fundPeriodicSettlement: "基金周期结算",
	depositAndWithdrawa: "充提记录",
	all: "全部",
	marginDeducted: "保证金扣除",
	initialFollow: "首次跟单",
	addFollowingAmount: "追加跟单金额",
	fundEnd: "基金到期",
	feeRate: "资费比例",
	loss: "亏损金额",
	refundRate: "返现比例",
	submitted: "已提交，预计24h内到账",
	currentCapital: "当前已跟单金额",
	followAccount: "跟单账户",
	myWallet: "我的钱包",
	start: "起始",
	upTo: "截止",
	lastSettlement: "最近结算时间",
	openLong: "开多",
	closeLong: "平多",
	openShort: "开空",
	closeShort: "平空",
	perpetual: "永续",
	spot: "现货",
	contentDetails: "明细",
	capitalAmountVerify: "请正确输入跟单金额",
	orderPercentageVerify: "请正确输入下单比例",
	copy: "复制",
	privacyPolicy: "隐私政策",
	managerDetails: "基金经理详情",
	statisticsTarget: "统计指标",
	allTrade: "全部交易",
	long: "多头",
	short: "空头",
	finalProfit: "净利润",
	totalProfit: "总盈利",
	totalLoss: "总亏损",
	profitAndLossRate: "盈亏比",
	winRate: "胜率",
	profitNumber: "盈利次数",
	lossNumber: "亏损次数",
	averageNetProfit: "平均利润",
	averageProfit: "平均盈利",
	averageLoss: "平均亏损",
	averageProfitAndLossRate: "平均盈亏比",
	maxProfit: "最大盈利",
	maxLoss: "最大亏损",
	maxProfitOrTotalProfit: "最大盈利/总盈利",
	maxLossOrTotalLoss: "最大亏损/总亏损",
	netProfitAndMaxLoss: "净利润/最大亏损",
	maxProfitNumber: "最大连续盈利次数",
	maxLossNumber: "最大连续亏损次数",
	averageHoldingPeriod: "平均持仓周期",
	averageProfitPeriod: "平均盈利周期",
	averageLossPeriod: "平均亏损周期",
	totalTradeCost: "交易成本合计",
	sharpeRatio: "夏普比率",
	totalPeriod: "总交易时间",
	holdingPeriodOrTotalPeriod: "持仓时间比率",
	holdingPeriod: "持仓时间",
	maxNonTrainingPeriod: "最大空仓时间",
	holdingBars: "持仓周期",
	maxWithdrawalRate: "最大回撤率",
	drawdownValue: "回撤值",
	occurTime: "发生时间",
	maxDrawdownValue: "最大回撤值",
	drawdown: "回撤率",
	allocationRisk: "配仓风险值",
	maxDrawdownWithoutLeverage: "未加杠杆单笔开仓最大跌幅",
	liquidationTimes: "爆仓次数",
	newFund: "创建基金",
	settledSharedProfit: "已结算分润",
	unsettledSharedProfit: "待结算分润",
	frozenSafetyNet: "冻结兜底金额",
	investorProfits: "跟单者收益",
	totalSharedProfit: "预计总分润",
	state: "状态",
	open: "开放中",
	expire: "已到期",
	profitDetail: "分润明细",
	alreadySettled: "已结算",
	investorHistoricalProfit: "跟单者历史收益",
	managerSettledSharedProfit: "基金经理结算分润",
	unsettled: "待结算",
	managerExpectedSharedProfit: "基金经理预期分润",
	sharedProfitRatio: "分润比例",
	settleTime: "结算时间",
	fundRange: "基金周期",
	investorProfit: "跟单者收益",
	cisSharedProfit: "平台分润",
	managerSharedProfit: "基金经理分润",
	cisExpectedSharedProfit: "平台预期分润",
	referenceStrategyAndInvestmentReport: "引用策略及投资报告",
	basicInformation: "基础信息",
	managerAccountRule: "基金经理的1个CIP交易账户仅可作为1只基金的基金账户",
	investorPlaceOrderRule: "跟单用户将完全跟随该账户的交易动作自动下单",
	expirationDate: "有效期",
	settledPeriod: "结算周期",
	month: "月",
	quarter: "季",
	sixMonths: "半年",
	year: "年",
	cipFundAccount: "CIP基金账户",
	followMode: "跟单模式",
	fullFollowRatio: "完全跟随比例",
	customRatio: "跟单者可自定义",
	fullFollowRatioTooltip: "跟单者单笔下单金额占跟单金额的比例 与基金经理仓位占比一致，最小为10USDT",
	customRatioTooltip: "跟单者单笔下单金额占跟单金额的比例 由跟单者自定义设置，最小为10USDT",
	returnAndRiskFactors: "收益及风险要素",
	estimatedMaxDrawdown: "预计最大回撤",
	stopLossLine: "止损线",
	safetyNetAmoun: "兜底金额",
	optional: "选填",
	tradingTargetRange: "交易标的范围",
	spotUnlimited: "现货不受限制",
	rangeDescription: "范围描述",
	leverageRange: "杠杆范围",
	add: "添加",
	minLeverage: "最小杠杆",
	maxLeverage: "最大杠杆",
	selectPair: "选择交易对",
	referenceStrategy: "引用策略",
	referenceReport: "引用投资报告",
	publish: "发布",
	publishDone: "发布成功",
	networkErrorTip: "网络异常，请检查网络或稍后重试",
	nameOccupied: "该名称已被占用，请修改",
	fundManagement: "基金管理",
	digitsLettersUnderscores: "请输入4-32位数字、字母、_、-、.",
	startDate: "开始日期",
	finishDate: "结束日期",
	alias: "别名",
	nameGeneral: "名称",
	period: "周期",
	lever: "杠杆",
	maxDrawdown: "最大回撤",
	fixedPosition: "固定仓位",
	dynamicPosition: "动态仓位",
	strategyPosition: "策略仓位",
	referenceStrategyAndInvestmentReportVerify: "引用至少1条策略或投资报告",
	apiKeyAndSecretVerify: "请正确输入API Key 和 API Secret",
	noData: "暂无数据",
	apiKeyVerify: "该API Key已导入，请更换",
	sinceIncep: "投资以来",
	uncheckDefaultSecurityControlsInBinanceWebAPIManagement: "为避免API过期导致交易失败，请在Binance网页版-API管理，取消【默认安全控制措施】的勾选",
	importedTradingAccountMustBeHedgeMode: "导入交易账户的持仓模式须为双向持仓",
	exchangeAccountInTradingModeAndCannotBeUpdated: "该交易所账户当前处于跟单中，不支持更新",
	updated: "已更新",
	pleaseEnsureNewApiKeySecretFromSameExchangeAccountAsBefore: "请确保新的API Key/Secret与之前导入来自同一交易所账户",
	failedTooManyTimesPleaseTryAgainTomorrow: "导入失败次数过多，请明日再试",
	select: "选择",
	selected: "已选",
	fundManagerStrategyBank: "基金经理策略库",
	notifications: "通知",
	invalid: "失效",
	allDeal: "全部交易",
	successful: "交易成功",
	reason: "失败原因",
	submittedPleaseCheckInHistoricalFunds: "已提交，请在历史基金中查看",
	stopping: "取消中",
	failed: "取消失败",
	closureFailurePleaseUpdateYourApiKey: "检测到API Key失效导致平仓失败，请更新API Key",
	ifUpdatedPleaseWaitForSystemAutomaticallyClosePosition: "若已更新，请等待系统自动平仓",
	cisInvestmentAgreement: "CIS跟单协议",
	agree: "同意",
	loading: "加载中",
	loadingFailedPleasePullDownOrClickReload: "加载失败，请下拉或点击重新加载",
	noMoreData: "没有更多数据",
	copied: "已复制",
	insufficientFundFrozenMargin: "您的基金 ∬ 冻结保证金不足，请尽快补充",
	failedTag: "失败",
	reload: "重新加载",
	backtestingReturn: "收益率",
	annualizedReturn: "年化收益率",
	sort: "排序",
	filter: "筛选",
	realTradingVerified: "通过实盘验证",
	backtestingVerified: "通过后验验证",
	followingFailedToast: "该基金未成功取消，当前不支持跟单",
	automaticallyStoppedPopover: "由于预计资费超过冻结保证金，已自动停止跟单",
	agreementToTheTermsOfService: "注册即表明同意",
	termsOfService: "服务协议",
	selectAtLeastOneOption: "至少选择一项",
	Refunded: "返现",
	capitalLossAmount: "亏损",
};
