import React from "react";
import styles from "./BasicInformation.less";
import useLanguage from "@shared/hooks/useLanguage";
import { DatePicker, DatePickerProps, Input, Radio, Select, Tooltip } from "antd";
import questionIcon from "@shared/assets/png/questionIcon.png";
import { BaseInformation, FollowType, SettlementPeriodType } from "@/manager/typings/fundFactor";
import { checkTimeIs0Clock, endMillsOfDay, startMillsOfDay, timezoneValue } from "@shared/utils/dateUtils";
import { getFundRequest } from "@shared/utils/request";
import { validateFundName } from "@/manager/utils/fundFactor";
import { StatusType } from "@shared/enum/enum";
import moment from "moment";
import { Dayjs } from "dayjs";

interface BasicInformationParams {
	data: BaseInformation;
	changeData: React.Dispatch<React.SetStateAction<BaseInformation>>;
	needCheckForm: boolean;
}

interface OriginAccounts {
	accountId: string;
	key: string;
	accountName: string;
	firstTradingTime: number;
}

interface AccountOptions {
	label: string;
	value: string;
}

export const BasicInformation = (props: BasicInformationParams) => {
	const { t, keys } = useLanguage();
	const { data, changeData, needCheckForm } = props;
	const [name, setName] = React.useState(data.name);
	const [fundNameStatus, setFundNameStatus] = React.useState<StatusType>(StatusType.Normal);
	const [startDate, setStartDate] = React.useState<number>(data.startDate);
	const [startDateStatus, setStartDateStatus] = React.useState<StatusType>(StatusType.Normal);
	const [endDate, setEndDate] = React.useState<number>(data.endDate);
	const [endDateStatus, setEndDateStatus] = React.useState<StatusType>(StatusType.Normal);
	const [settlementPeriod, setSettlementPeriod] = React.useState(data.settlementPeriod);
	const [fundAccount, setFundAccount] = React.useState(data.fundAccount);
	const [followType, setFollowType] = React.useState(data.followType);
	const [accountOptions, setAccountOptions] = React.useState<AccountOptions[]>([]);
	const [fundAccountStatus, setFundAccountStatus] = React.useState<StatusType>(StatusType.Normal);
	const [createdNames, setCreateNames] = React.useState<string[]>([]);
	const [nameExistWarning, setNameExistWarning] = React.useState<boolean>(false);

	const validateInputData = (data: string) => {
		if (data.length > 32 || data.length < 1) {
			return false;
		} else {
			return validateFundName(data);
		}
	};

	const needNameExistWarning = (status: boolean) => {
		if (status) {
			setFundNameStatus(StatusType.Error);
			setNameExistWarning(true);
		} else {
			setFundNameStatus(StatusType.Normal);
			setNameExistWarning(false);
		}
	};

	const onFundNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const data = e.target.value;
		setName(data);
		createdNames.some((name) => name === data) ? needNameExistWarning(true) : needNameExistWarning(false);
		validateInputData(data) ? setFundNameStatus(StatusType.Normal) : setFundNameStatus(StatusType.Error);
	};

	const onStartDateChange: DatePickerProps["onChange"] = (date) => {
		if (date) {
			setStartDateStatus(StatusType.Normal);
			const time = moment(date.format("YYYY-MM-DD")).utc().valueOf() - timezoneValue;
			console.log(time, new Date(time));

			setStartDate(time);
		} else {
			setStartDate(NaN);
			setStartDateStatus(StatusType.Error);
		}
	};

	const onEndDateChange: DatePickerProps["onChange"] = (date) => {
		if (date) {
			setEndDateStatus(StatusType.Normal);
			const time = moment(date.format("YYYY-MM-DD")).utc().valueOf() - timezoneValue + 3600 * 1000 * 24 - 1;
			console.log(time, new Date(time));
			setEndDate(time);
		} else {
			setEndDate(NaN);
			setEndDateStatus(StatusType.Error);
		}
	};

	React.useEffect(() => {
		getFundRequest("/fund/uncreatedTradingAccounts", {}).then((res) => {
			const data = res as OriginAccounts[];
			const accounts: AccountOptions[] = data.map((item) => {
				return {
					label: item.accountName,
					value: item.accountId,
				};
			});
			setAccountOptions(accounts);
		});
		getFundRequest("/fund/names", {}).then((res) => {
			const names = res as string[];
			setCreateNames(names);
		});
	}, []);

	React.useEffect(() => {
		const changedData: BaseInformation = {
			name: name,
			startDate: startDate,
			endDate: endDate,
			settlementPeriod: settlementPeriod,
			fundAccount: fundAccount,
			followType: followType,
			isNameExist: nameExistWarning,
		};

		changeData(changedData);
	}, [name, startDate, endDate, settlementPeriod, fundAccount, followType, changeData, nameExistWarning]);

	React.useEffect(() => {
		if (needCheckForm === true) {
			validateFundName(name) ? setFundNameStatus(StatusType.Normal) : setFundNameStatus(StatusType.Error);
			startDate === 0 ? setStartDateStatus(StatusType.Error) : setStartDateStatus(StatusType.Normal);
			endDate === 0 ? setEndDateStatus(StatusType.Error) : setEndDateStatus(StatusType.Normal);
			fundAccount === "" ? setFundAccountStatus(StatusType.Error) : setFundAccountStatus(StatusType.Normal);
		}
	}, [needCheckForm, name, startDate, endDate, fundAccount]);

	const disabledStartDate = (current: Dayjs): boolean => {
		const today = moment();
		return (
			current.valueOf() >= today.startOf("day").valueOf() + 3600 * 1000 * 24 ||
			current.valueOf() < today.startOf("day").valueOf()
		);
	};

	const disabledEndDate = (current: Dayjs): boolean => {
		const today = moment();
		return startDate ? current.valueOf() < startDate : current.valueOf() < today.startOf("day").valueOf();
	};

	return (
		<div className={styles["basic-information"]}>
			<div>
				<div className={styles["title"]}>{t(keys.basicInformation)}</div>
				<div className={styles["content"]}>
					<div>
						<div>{t(keys.name)}</div>
						<Input
							placeholder={t(keys.digitsLettersUnderscores)}
							value={name}
							onChange={onFundNameChange}
							status={fundNameStatus}
							allowClear={true}
						/>
						{nameExistWarning ? <div className={styles["name-warning"]}>{t(keys.nameOccupied)}</div> : null}
					</div>
					<div>
						<div>{t(keys.expirationDate)}</div>
						<div>
							<DatePicker
								placeholder={t(keys.startDate)}
								onChange={onStartDateChange}
								status={startDateStatus}
								disabledDate={disabledStartDate}
							/>
							<span>to</span>
							<DatePicker
								placeholder={t(keys.finishDate)}
								onChange={onEndDateChange}
								status={endDateStatus}
								disabledDate={disabledEndDate}
							/>
						</div>
					</div>
					<div>
						<div>{t(keys.settledPeriod)}</div>
						<div>
							<Radio.Group value={settlementPeriod} onChange={(e) => setSettlementPeriod(e.target.value)}>
								<Radio value={SettlementPeriodType.Month}>{t(keys.month)}</Radio>
								<Radio value={SettlementPeriodType.Quarter}>{t(keys.quarter)}</Radio>
								<Radio value={SettlementPeriodType.HalfYear}>{t(keys.sixMonths)}</Radio>
								<Radio value={SettlementPeriodType.Year}>{t(keys.year)}</Radio>
							</Radio.Group>
						</div>
					</div>
					<div>
						<div>
							{t(keys.cipFundAccount)}
							<Tooltip
								title={
									<div>
										<div>· {t(keys.managerAccountRule)}</div>
										<div>· {t(keys.investorPlaceOrderRule)}</div>
									</div>
								}
							>
								<img src={questionIcon} style={{ width: "16px" }} />
							</Tooltip>
						</div>
						<div>
							<Select
								options={accountOptions}
								onChange={(e) => setFundAccount(e)}
								status={fundAccountStatus}
							/>
						</div>
					</div>
					<div>
						<div>{t(keys.followMode)}</div>
						<div>
							<Radio.Group value={followType} onChange={(e) => setFollowType(e.target.value)}>
								<Radio value={FollowType.Proportional}>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										{t(keys.fullFollowRatio)}
										<Tooltip title={t(keys.fullFollowRatioTooltip)}>
											<img src={questionIcon} style={{ width: "16px" }} />
										</Tooltip>
									</div>
								</Radio>
								<Radio value={FollowType.NonProportion}>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										{t(keys.customRatio)}
										<Tooltip title={t(keys.customRatioTooltip)}>
											<img src={questionIcon} style={{ width: "16px" }} />
										</Tooltip>
									</div>
								</Radio>
							</Radio.Group>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
