import styles from "./SwitchIdentities.less";
import useLanguage from "@shared/hooks/useLanguage";

export default function SwitchIdentities() {

	const { t, keys } = useLanguage();

	return (
		<div className={styles["identity"]}>
			<div className={styles["identity_text"]}>{t(keys.fundManager)}</div>
			<div className={styles["identity_icon"]}></div>
		</div>
	);
}