import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TopNavigator.less";
import { Menu } from "antd";
import type { MenuProps } from "antd";
import cisIcon from "@shared/assets/png/CIS.png";
import UserMenu from "./UserMenu";
import useLanguage from "@shared/hooks/useLanguage";

interface Props {
	currentKey: string;
	setCurrentKey: (value: string) => void;
}

export default function TopNavigator(props: Props) {
	const { t, keys } = useLanguage();
	const items: MenuProps["items"] = [
		{
			label: t(keys.fundManagement),
			key: "fundManagement",
		}
	];
	const { currentKey, setCurrentKey } = props;
	const navigate = useNavigate();
	const onClick: MenuProps["onClick"] = (e) => {
		setCurrentKey(e.key);
		!(e.key === currentKey) && navigate(e.key);
	};

	return (
		<div className={styles["top-nav-container"]}>
			<div>
				<img src={cisIcon} />
			</div>
			<Menu items={items} mode="horizontal" selectedKeys={[currentKey!]} onClick={onClick} />
			<UserMenu />
		</div>
	);
}
