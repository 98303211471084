import { useEffect } from "react";
import { fetchStrategiesList, getPairInfo } from "../service/strategies";
import Page from "@shared/typings/page";
import { StrategiesTable } from "../typings/importStrategies";
import { Pair } from "@shared/enum/enum";

export const useUpdateStrategies = (setStrategies: (val: StrategiesTable[]) => void) => {
	useEffect(() => {
		const fetchStrategies = async () => {
			const res = (await fetchStrategiesList()) as Page<StrategiesTable>;
			setStrategies(res.data);
		};
		fetchStrategies();
	}, [setStrategies]);
};

export const useGetPairs = (setPairs: React.Dispatch<React.SetStateAction<Pair[]>>) => {
	useEffect(() => {
		const fetchPair = async () => {
			const res = (await getPairInfo()) as Pair[];
			setPairs(res);
		};
		fetchPair();
	}, [setPairs]);
};