import useLanguage from "@shared/hooks/useLanguage";
import type { ColumnsType } from "antd/es/table";
import miniIcon from "@shared/assets/png/miniChart.png";
import medalIcon from "@shared/assets/png/medal.png";
import silverIcon from "@shared/assets/png/silverMedal.png";
import shieldIcon from "@shared/assets/png/shield.png";
import ProfitDetails from "../components/ProfitDetails/ProfitDetails";
import { fetchFunds, fetchSummary, fetchCumulativeReturn } from "../service/myfund";
import { useState, useEffect } from "react";
import { formatAssetsNum, formatPercentageNum, amountConverter } from "@shared/utils/numberUtils";
import moment from "moment";

interface Summary {
	settledShare: number;
	unsettledShare: number;
	safetyNetAmount: number;
}

interface Funds {
	fundId: string;
	fundName: string;
	expectedAnnualizedReturn: number;
	actualReturn: number;
	scale: number;
	followers: number;
	status: string;
	backTestChecked: boolean;
	actualChecked: boolean;
	safetyNetAmount: number;
	followType: string;
	fundAccount: string;
	investmentReportStart: number;
	investmentReportEnd: number;
	followerIncome: number;
	totalShare: number;
	settledShare: number;
	unsettledShare: number;
}

interface CumulativeData {
	day: number;
	accumulativeReturn: number;
}

function useGetTableColumn(setFundId: (value: string) => void, setIsModalOpen: (value: boolean) => void) {
	const { t, keys } = useLanguage();
	const statusMap: Record<string, string> = {
		OPENING: t(keys.open),
		CLOSED: t(keys.closed),
		EXPIRED: t(keys.expire),
	};
	const columns: ColumnsType<Funds> = [
		{
			title: t(keys.name),
			width: "200px",
			dataIndex: "fundName",
			key: "fundName",
			align: "center",
			render: (_, record) => (
				<div>
					{record.safetyNetAmount > 0 && (
						<div className="safety-net">
							{t(keys.safetyNetAmoun)}
							&nbsp;{amountConverter(record.safetyNetAmount)}
						</div>
					)}
					{
						<div className="medal-icon">
							{record.actualChecked && <img src={medalIcon} />}
							{!record.actualChecked && record.backTestChecked && <img src={silverIcon} />}
							{record.safetyNetAmount > 0 && <img src={shieldIcon} />}
							<div className="fundName">{record.fundName}</div>
						</div>
					}
				</div>
			),
		},
		{
			title: t(keys.expectedApr),
			width: "130px",
			dataIndex: "expectedAnnualizedReturn",
			key: "expectedAnnualizedReturn",
			align: "center",
			render: (_, record) => <div>{formatPercentageNum(record.expectedAnnualizedReturn)}</div>,
		},
		{
			title: t(keys.aum),
			width: "130px",
			dataIndex: "scale",
			key: "scale",
			align: "center",
			render: (_, record) => <div>{formatAssetsNum(record.scale, 2)}</div>,
		},
		{
			title: t(keys.followedInvestors),
			width: "160px",
			dataIndex: "followers",
			key: "followers",
			align: "center",
		},
		{
			title: t(keys.return),
			width: "150px",
			dataIndex: "actualReturn",
			key: "actualReturn",
			align: "center",
			render: (_, record) => (
				<div className="mini-chart">
					{formatPercentageNum(record.actualReturn)}
					<img
						src={miniIcon}
						onClick={() => {
							setFundId(record.fundId);
							setIsModalOpen(true);
						}}
						style={{ cursor: "pointer" }}
					/>
				</div>
			),
		},
		{
			title: t(keys.investorProfits),
			width: "150px",
			dataIndex: "followerIncome",
			key: "followerIncome",
			align: "center",
			render: (_, record) => <div>{formatAssetsNum(record.followerIncome, 2)}</div>,
		},
		{
			title: t(keys.totalSharedProfit),
			width: "190px",
			dataIndex: "totalShare",
			key: "totalShare",
			align: "center",
			render: (_, record) => <div>{formatAssetsNum(record.totalShare, 2)}</div>,
		},
		{
			title: t(keys.settledSharedProfit),
			width: "180px",
			dataIndex: "settledShare",
			key: "settledShare",
			align: "center",
			render: (_, record) => <div>{formatAssetsNum(record.settledShare, 2)}</div>,
		},
		{
			title: t(keys.unsettledSharedProfit),
			width: "190px",
			dataIndex: "unsettledShare",
			key: "unsettledShare",
			align: "center",
			render: (_, record) => <div>{formatAssetsNum(record.unsettledShare, 2)}</div>,
		},
		{
			title: t(keys.state),
			width: "90px",
			dataIndex: "status",
			key: "status",
			align: "center",
			render: (_, record) => statusMap[record.status],
		},
		{
			title: t(keys.profitDetail),
			width: "130px",
			key: "ProfitDetails",
			fixed: "right",
			align: "center",
			render: (_, record) => (
				<div className="profit-detail">
					<ProfitDetails />
				</div>
			),
		},
	];

	return columns;
}

function useGetFunds(page: number, size: number) {
	const [totalElements, setTotalElements] = useState<number>(0);
	const [myFunds, setMyFunds] = useState<Funds[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		setIsLoading(true);
		fetchFunds(page, size).then((res: any) => {
			setTotalElements(res!.totalElements);
			setMyFunds(res.data);
			setIsLoading(false);
		});
	}, [page, size]);

	return { totalElements, myFunds, isLoading };
}

function useGetSummary() {
	const [summary, setSummary] = useState<Summary>({} as Summary);
	useEffect(() => {
		fetchSummary().then((res) => {
			setSummary(res as Summary);
		});
	}, []);

	return { summary };
}

function useGetReturnCurve(fundId: string) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [returnCurve, setReturnCurve] = useState<[string, number][]>([]);
	useEffect(() => {
		setIsLoading(true);
		fetchCumulativeReturn(fundId).then((res) => {
			const data = formatCumulativeChartData(res as CumulativeData[]);
			setReturnCurve(data);
			setIsLoading(false);
		});
	}, [fundId]);

	return { returnCurve, isLoading };
}

const formatCumulativeChartData = (data: CumulativeData[]): [string, number][] => {
	return data.map((item) => {
		return [moment(item.day).format("YYYY-MM-DD"), item.accumulativeReturn];
	});
};

export { useGetTableColumn, useGetFunds, useGetSummary, useGetReturnCurve };
