export const zh_HK = {
	fundParadise: "基金廣場",
	fundDetails: "基金詳情",
	currentProfit: "當期收益",
	totalCapital: "跟單總金額",
	walletBalance: "錢包餘額",
	deposit: "充值",
	frozenMargin: "凍結保證金",
	availableBalance: "可用餘額",
	allHistoricalProfit: "歷史總收益",
	myFunds: "我的基金",
	transactionSummary: "賬單明細",
	quantFund: "量化基金",
	valueFund: "價值基金",
	themeFund: "主題基金",
	comingSoon: "即將上線",
	name: "基金名稱",
	expectedApr: "預期年化收益率",
	apr: "年化收益率",
	aum: "規模",
	followedInvestors: "跟單人數",
	return: "實盤收益率",
	sinceInception: "成立以來",
	follow: "跟單",
	addInvestment: "追加跟單",
	addFollow: "追加",
	managerSafetyNet: "基金經理兜底",
	alreadyRealTradingVerified: "∬ 月實盤驗證",
	alreadyBacktestingVerified: "∬ 月後驗驗證",
	cumulativeReturn: "累計收益率",
	followSetting: "跟單設置",
	fund: "基金",
	capital: "跟單金額",
	exchangeAccount: "交易所賬戶",
	importExchangeAccount: "導入交易所賬戶",
	exchangeAccountBalance: "交易所賬戶餘額",
	orderPercentage: "下單比例",
	ratioSingleOrderAmountToCapital: "單筆下單金額佔跟單金額比例",
	positionRatioEqualsFundManagerMinAmountMoreThanTenUSDT: "倉位比例與基金經理一致，最小為10USDT",
	investmentPeriods: "跟單期數",
	range: "期數範圍",
	inputPeriods: "輸入跟單期數",
	followFee: "跟單資費",
	managementFee: "投資管理費",
	commission: "交易佣金",
	periodAt: "第 ∬ 期",
	end: "結束",
	insufficientBalancePleaseDepositAsap: "可用餘額不足，請盡快充值",
	pleaseImportExchangeAccountFirst: "請先導入交易所賬戶",
	startOfPeriodFrozenMargin: "期初預支付凍結保證金",
	endOfPeriodSettlement: "期末結算",
	frozenMarginReleasedToFollowerAccountIfLoss: "若虧損，釋放凍結保證金至跟單者CIS賬戶",
	oneFifthCurrentProfitAsSharedDeductedIfProfitRemainingReleased: "",
	followed: "已跟單",
	selectAccount: "選擇跟單賬戶",
	balance: "餘額",
	runtime: "成立時間",
	dayAt: " ∬ 天",
	nextSettlement: "下一結算日",
	fundEndDate: "基金到期日",
	realNetProfit: "實盤淨利潤",
	investmentReport: "投資報告",
	fundStrategy: "基金策略",
	fundElements: "基金要素",
	instruction: "跟單說明",
	pair: "交易對",
	leverageTimes: "槓桿倍數",
	initialBacktestingCapital: "後驗初始資金",
	positionMode: "倉位模式",
	dateRange: "日期範圍",
	cumulativeReturnCurve: "累計收益率折線圖",
	pAndL: "盈虧曲線圖",
	tradeTimes: "交易次數",
	winRatio: "勝率",
	fundRiskReturnCharacteristics: "基金風險收益特徵",
	expectedDrawdown: "預期最大回撤率",
	renewalPeriod: "續存期限",
	inceptionDate: "成立日",
	subscriptionClosingDate: "募集結束日",
	operationMode: "運作方式",
	openEnd: "開放式",
	closedEnd: "封閉式",
	incentiveFee: "業績報酬",
	stopLossRatio: "止損比例",
	tradingTargets: "交易標的範圍",
	futures: "合約",
	leverage: "槓桿範圍",
	followedInvestorsTotal: "跟單總人次",
	totalProfitOfFollowedInvestors: "跟單者總收益",
	closed: "已終止",
	tradingRecord: "交易記錄",
	tradingRecordShort: "交易記錄",
	stop: "取消跟單",
	stopFollowing: "取消跟單",
	singleCapitalAmountToAvailableAmountConsistentWithManagerAndMinimumTen: "單筆下單金額佔跟單金額中可用金額比例 與基金經理倉位佔比一致，最小為10U",
	stopCopyAndOrdersStopOtherwiseExistOpenCopyingPositions: "取消跟單後，將立即停止跟隨下單，若有跟單開倉的部位，將自動市價平倉",
	settlementAfterStoppingDayFrozenMarginReleasedFollowingDay: "取消跟單後立即結算；已凍結的保證金立即釋放",
	confirm: "確定",
	cancel: "取消",
	settled: "已結算",
	current: "當期",
	pending: "未開始",
	marginFrozen: "保證金凍結",
	marginReleased: "保證金釋放",
	safetyNetAmountRefunded: "兜底金額返現",
	details: "詳情",
	time: "時間",
	type: "類型",
	initialFollowing: "首次跟單",
	additionalFollowing: "追加跟單",
	amount: "金額",
	account: "賬戶",
	paidAmount: "實付資費",
	lossAmount: "虧損金額",
	crypto: "商品",
	dealTime: "成交時間",
	direction: "方向",
	filled: "成交數量",
	price: "成交價格",
	summary: "匯總",
	profit: "收益",
	update: "更新",
	exchange: "交易所",
	pleaseInput: "請輸入",
	hedgeMode: "雙向持倉",
	pleaseEnsureSameAPIKeySecretAsBefore: "請確保新的API Key/Secret與之前導入來自同一交易所賬戶",
	register: "註冊",
	email: "郵箱",
	verificationCode: "驗證碼",
	send: "獲取驗證碼",
	next: "下一步",
	previous: "上一步",
	alreadyHaveAccount: "已有賬號",
	toLogIn: "前往登錄",
	noAccount: "沒有賬號",
	registerNow: "前往註冊",
	passwordForgotten: "忘記密碼",
	emailTip: "郵箱將作為CIS登錄賬號",
	emailNotRegistered: "郵箱未註冊",
	resend: "重發",
	pleaseInputValidEmailAddress: "請輸入合法的郵箱地址",
	emailAlreadyRegistered: "郵箱已註冊",
	pleaseInputCorrectVerificationCode: "請正確輸入驗證碼",
	verificationCodeExpired: "驗證碼已過期",
	setPassword: "設置密碼",
	eightToSixteenDigitsOrLetterCaseSensitive: "8 - 16位數字、字母組合，區分大小寫",
	confirmPassword: "確認密碼",
	passwordDifferent: "密碼不一致",
	registeredSuccess: "註冊成功",
	logIn: "登錄",
	user: "賬號",
	password: "密碼",
	incorrectAccountPasswordPleaseTryAgain: "賬號或密碼錯誤，請重新輸入",
	resetPassword: "重置密碼",
	setNewPassword: "設置新密碼",
	finished: "完成",
	success: "成功",
	withdraw: "提現",
	logOut: "退出",
	followTrader: "跟單用戶",
	fundManager: "基金經理",
	investor: "跟單用戶",
	selectWalletProtocol: "選擇錢包協議",
	securityTip: "安全提示",
	confirmSelectedProtocol: "請務必確認已選協議",
	completeTransferThroughWallet: "使用錢包APP掃碼或複制下方錢包地址完成轉賬",
	copyAddress: "複製地址",
	accumulativeDeposit: "累計充值到賬",
	cumulativeWithdrawal: "累計提現到賬",
	arrivalTime: "到賬時間",
	amountReceived: "到賬金額",
	protocol: "協議",
	reset: "重置",
	inTotal: "共 ∬ 項",
	walletAddress: "錢包地址",
	profitDetails: "收益明細",
	investmentProfit: "投資收益",
	insufficientFrozenMargin: "凍結保證金不足",
	insufficientFrozenMarginPleaseAdd: "凍結保證金不足，請盡快補充",
	addMarginShort: "補充保證金",
	automaticStop: "自動停止跟單",
	fundStoppedBecauseEstimatedFeeLargerThanFrozenMargin: "基金 ∬ 由於預計資費超過凍結保證金，已自動停止跟單",
	view: "查看",
	frozenFromAvailableBalanceInWallet: "從CIS錢包的可用餘額中凍結",
	oneFifthProfitDeductedAsFeesFromFrozenMarginAfterSettlement: "基金結算後，將實際收益的20%作為資費從凍結保證金中扣除",
	currentFund: "當前基金",
	historicalFund: "歷史基金",
	insufficientMargin: "不足",
	addMargin: "補充保證金",
	estimatedFee: "預計資費",
	pleaseAddFrozenMargin: "請盡快補充凍結保證金",
	stopFollowWhenMoreThanOneHundred: "當 ≥ 100%時將自動停止跟單",
	historicalProfit: "歷史收益",
	totalProfitAfterSettlement: "每次結算後收益合計",
	unsettledProfit: "待結算收益",
	expectedProfit: "預期收益",
	ProfitAfterDeductingFeeFromUnsettledProfit: "待結算收益扣除資費後收益",
	successCancel: "已取消跟單",
	addFrozenMargin: "補充凍結保證金",
	addAmount: "補充金額",
	inputAmount: "請輸入補充金額",
	settlementRecord: "結算記錄",
	settlementTime: "結算時間",
	stopFollowingSettlement: "停止跟單結算",
	fee: "資費",
	netProfit: "淨收益",
	fundPeriodicSettlement: "基金周期結算",
	depositAndWithdrawa: "充提記錄",
	all: "全部",
	marginDeducted: "保證金扣除",
	initialFollow: "首次跟單",
	addFollowingAmount: "追加跟單金額",
	fundEnd: "基金到期",
	feeRate: "資費比例",
	loss: "虧損金額",
	refundRate: "返現比例",
	submitted: "已提交，預計24h內到賬",
	currentCapital: "當前已跟單金額",
	followAccount: "跟單賬戶",
	myWallet: "我的錢包",
	start: "起始",
	upTo: "截止",
	lastSettlement: "最近結算時間",
	openLong: "開多",
	closeLong: "平多",
	openShort: "開空",
	closeShort: "平空",
	perpetual: "永續",
	spot: "現貨",
	contentDetails: "明細",
	capitalAmountVerify: "請正確輸入跟單金額",
	orderPercentageVerify: "請正确輸入下單比例",
	copy: "複製",
	privacyPolicy: "隱私政策",
	managerDetails: "基金經理詳情",
	statisticsTarget: "統計指標",
	allTrade: "全部交易",
	long: "多頭",
	short: "空頭",
	finalProfit: "淨利潤",
	totalProfit: "總盈利",
	totalLoss: "總虧損",
	profitAndLossRate: "盈虧比例",
	winRate: "贏率",
	profitNumber: "盈利次數",
	lossNumber: "虧損次數",
	averageNetProfit: "平均利潤",
	averageProfit: "平均盈利",
	averageLoss: "平均虧損",
	averageProfitAndLossRate: "平均盈虧比例",
	maxProfit: "最大盈利",
	maxLoss: "最大虧損",
	maxProfitOrTotalProfit: "最高盈利/總盈利",
	maxLossOrTotalLoss: "最高虧損/總虧損",
	netProfitAndMaxLoss: "淨利潤/最大虧損",
	maxProfitNumber: "最高連續獲利次數",
	maxLossNumber: "最高連續虧損次數",
	averageHoldingPeriod: "平均持倉週期",
	averageProfitPeriod: "平均盈利週期",
	averageLossPeriod: "平均虧損週期",
	totalTradeCost: "交易成本合計",
	sharpeRatio: "夏普比率",
	totalPeriod: "總交易時間",
	holdingPeriodOrTotalPeriod: "持倉時間比率",
	holdingPeriod: "持倉時間",
	maxNonTrainingPeriod: "最長空倉時間",
	holdingBars: "持倉週期",
	maxWithdrawalRate: "最大撤回率",
	drawdownValue: "撤回值",
	occurTime: "發生時間",
	maxDrawdownValue: "最大撤回值",
	drawdown: "撤回率",
	allocationRisk: "配倉風險值",
	maxDrawdownWithoutLeverage: "未加槓桿單筆開倉最大跌幅",
	liquidationTimes: "爆倉次數",
	newFund: "創建基金",
	settledSharedProfit: "已結算分潤",
	unsettledSharedProfit: "待結算分潤",
	frozenSafetyNet: "凍結兜底金額",
	investorProfits: "跟單者收益",
	totalSharedProfit: "預計總分潤",
	state: "狀態",
	open: "開放中",
	expire: "已到期",
	profitDetail: "分潤明細",
	alreadySettled: "已結算",
	investorHistoricalProfit: "跟單者歷史收益",
	managerSettledSharedProfit: "基金經理結算分潤",
	unsettled: "待結算",
	managerExpectedSharedProfit: "基金經理預期分潤",
	sharedProfitRatio: "分潤比例",
	settleTime: "結算時間",
	fundRange: "基金周期",
	investorProfit: "跟單者收益",
	cisSharedProfit: "平台分潤",
	managerSharedProfit: "基金經理分潤",
	cisExpectedSharedProfit: "平台預期分潤",
	referenceStrategyAndInvestmentReport: "引用策略及投資報告",
	basicInformation: "基礎信息",
	managerAccountRule: "基金經理的1個CIP交易賬戶僅可作為1只基金的基金賬戶",
	investorPlaceOrderRule: "跟單用戶將完全跟隨該賬戶的交易動作自動下單",
	expirationDate: "有效期",
	settledPeriod: "結算週期",
	month: "月",
	quarter: "季",
	sixMonths: "半年",
	year: "年",
	cipFundAccount: "CIP基金賬戶",
	followMode: "跟單模式",
	fullFollowRatio: "完全跟隨比例",
	customRatio: "跟單者可自定義",
	fullFollowRatioTooltip: "跟單者單筆下單金額佔跟單金額的比例 與基金經理倉位佔比一致，最小為10USDT",
	customRatioTooltip: "跟單者單筆下單金額佔跟單金額的比例 由跟單者自定義設置，最小為10USDT",
	returnAndRiskFactors: "收益及風險要素",
	estimatedMaxDrawdown: "預計最大回撤",
	stopLossLine: "止損線",
	safetyNetAmoun: "兜底金額",
	optional: "選填",
	tradingTargetRange: "交易標的範圍",
	spotUnlimited: "現貨不受限制",
	rangeDescription: "範圍描述",
	leverageRange: "槓桿範圍",
	add: "添加",
	minLeverage: "最小槓桿",
	maxLeverage: "最大槓桿",
	selectPair: "選擇交易對",
	referenceStrategy: "引用策略",
	referenceReport: "引用投資報告",
	publish: "發布",
	publishDone: "發布成功",
	networkErrorTip: "網絡異常，請檢查網絡或稍後重試",
	nameOccupied: "該名稱已被佔用，請修改",
	fundManagement: "基金管理",
	digitsLettersUnderscores: "請輸入4-32位數字、字母、_、-、.",
	startDate: "開始日期",
	finishDate: "結束日期",
	alias: "別名",
	nameGeneral: "名稱",
	period: "週期",
	lever: "槓桿",
	maxDrawdown: "最大撤回",
	fixedPosition: "固定倉位",
	dynamicPosition: "動態倉位",
	strategyPosition: "策略倉位",
	referenceStrategyAndInvestmentReportVerify: "引用至少1條策略或投資報告",
	apiKeyAndSecretVerify: "請正確輸入API Key 和 API Secret",
	noData: "暫無數據",
	apiKeyVerify: "該API Key已導入，請更換",
	sinceIncep: "投資以來",
	uncheckDefaultSecurityControlsInBinanceWebAPIManagement: "為避免API過期導致交易失敗，請在Binance網頁版-API管理，取消【預設安全控制措施】的勾選",
	importedTradingAccountMustBeHedgeMode: "導入交易帳戶的持倉模式須為雙向持倉",
	exchangeAccountInTradingModeAndCannotBeUpdated: "該交易所帳戶目前處於跟單中，不支援更新",
	updated: "已更新",
	pleaseEnsureNewApiKeySecretFromSameExchangeAccountAsBefore: "請確保新的API Key/Secret與先前匯入來自同一交易所帳戶",
	failedTooManyTimesPleaseTryAgainTomorrow: "導入失敗次數過多，請明日再試",
	select: "選擇",
	selected: "已選",
	fundManagerStrategyBank: "基金經理策略庫",
	notifications: "通知",
	invalid: "失效",
	allDeal: "全部交易",
	successful: "交易成功",
	reason: "失敗原因",
	submittedPleaseCheckInHistoricalFunds: "已提交，請在歷史基金中查看",
	stopping: "取消中",
	failed: "取消失敗",
	closureFailurePleaseUpdateYourApiKey: "偵測到API Key失效導致平倉失敗，請更新API Key",
	ifUpdatedPleaseWaitForSystemAutomaticallyClosePosition: "若已更新，請等待系統自動平倉",
	cisInvestmentAgreement: "CIS跟單協議",
	agree: "同意",
	loading: "載入中",
	loadingFailedPleasePullDownOrClickReload: "加載失敗，請下拉或點擊重新加載",
	noMoreData: "沒有更多數據",
	copied: "已複製",
	insufficientFundFrozenMargin: "您的基金 ∬ 凍結保證金不足，請盡快補充",
	failedTag: "失敗",
	reload: "重新加載",
	backtestingReturn: "收益率",
	annualizedReturn: "年化收益率",
	sort: "排序",
	filter: "篩選",
	realTradingVerified: "透過實盤驗證",
	backtestingVerified: "通過後驗驗證",
	followingFailedToast: "該基金未成功取消，目前不支持跟單",
	automaticallyStoppedPopover: "由於預計資費超過凍結保證金，已自動停止跟單",
	agreementToTheTermsOfService: "註冊即表明同意",
	termsOfService: "服務協議",
	selectAtLeastOneOption: "至少選擇一項",
	Refunded: "返現",
	capitalLossAmount: "虧損",
};
