import React from "react";
import { Steps } from "antd";
import StepsButtons from "./StepsButtons";
import styles from "./StepsBar.less";
import { Key } from "antd/es/table/interface";
import { ReferenceInvestmentReport } from "@/manager/typings/importInvestmentReport";
import { useSteps } from "@/manager/utils/stepsConfigManagement";
import { FundFactorParams, initialFundFactorParams } from "../../typings/fundFactor";
import { useCreateFund } from "@/manager/hooks/createFundServiceHooks";

export default function StepsBar() {
	const [current, setCurrent] = React.useState(0);
	const [validate, setValidate] = React.useState<boolean>(false);
	const [referenceStrategiesInit, setReferenceStrategiesInit] = React.useState<boolean>(false);
	const [referenceInvReportInit, setReferenceInvReportInit] = React.useState<boolean>(false);
	const [submit, setSubmit] = React.useState(false);
	const [referenceStrategies, setReferenceStrategies] = React.useState<Key[]>([]);
	const [hasReferenceStrategies, setHasReferenceStrategies] = React.useState(false);
	const [referenceInvestmentReport, setReferenceInvestmentReport] = React.useState<ReferenceInvestmentReport>();
	const [hasReferenceInvestmentReport, setHasReferenceInvestmentReport] = React.useState(false);
	const [fundFactorParams, setFundFactorParams] = React.useState<FundFactorParams>(initialFundFactorParams);
	const [needCheckForm, setNeedCheckForm] = React.useState<boolean>(false);

	const { steps, items } = useSteps({
		current,
		referenceStrategies,
		setReferenceStrategies,
		setReferenceInvestmentReport,
		setHasReferenceStrategies,
		setHasReferenceInvestmentReport,
		validate,
		setValidate,
		setReferenceStrategiesInit,
		setReferenceInvReportInit,
		submit,
		setSubmit,
		fundFactorParams,
		setFundFactorParams,
		needCheckForm,
	});

	useCreateFund(
		submit,
		validate,
		fundFactorParams,
		referenceStrategies,
		referenceInvestmentReport,
		hasReferenceStrategies,
		hasReferenceInvestmentReport,
		referenceStrategiesInit,
		referenceInvReportInit,
		setReferenceStrategiesInit,
		setReferenceInvReportInit,
		setSubmit,
		setValidate
	);

	React.useEffect(() => {
		setNeedCheckForm(false);
	}, [needCheckForm]);

	return (
		<div className={styles["steps"]}>
			<Steps current={current} items={items} />
			<div className={styles["steps-content"]}>{steps[current].content}</div>
			<StepsButtons
				current={current}
				setCurrent={setCurrent}
				steps={steps}
				setSubmit={setSubmit}
				fundFactorParams={fundFactorParams}
				setNeedCheckFrom={() => setNeedCheckForm(true)}
			/>
		</div>
	);
}
