import React, { useEffect, useState } from "react";
import styles from "./MyfundTable.less";
import { Table } from "antd";
import { useGetTableColumn } from "@/manager/hooks/myFund";
import useLanguage from "@shared/hooks/useLanguage";
import { replaceVariable } from "@shared/i18next/utils/replaceUtils";
import { useGetFunds } from "@/manager/hooks/myFund";
import ReturnCurveModal from "./ReturnCurveModal";

export default function MyfundTable() {
	const { t, keys } = useLanguage();
	const [fundId, setFundId] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const columns = useGetTableColumn(setFundId, setIsModalOpen);
	const [page, setPage] = useState<number>(1);
	const { totalElements, myFunds, isLoading } = useGetFunds(page, 12);
	useEffect(() => {
		myFunds.length > 0 && setFundId(myFunds[0].fundId);
	}, [myFunds]);

	return (
		<div className={styles["myfund-table-container"]}>
			{fundId && (
				<>
					<Table
						columns={columns}
						dataSource={myFunds}
						scroll={{ x: "max-content" }}
						sticky={{ offsetHeader: 0, offsetScroll: 0 }}
						pagination={{
							disabled: isLoading,
							pageSize: 12,
							current: page,
							total: totalElements,
							position: ["bottomRight"],
							hideOnSinglePage: true,
							showTotal(total, range) {
								return <div>{replaceVariable(t(keys.inTotal), String(total))}</div>;
							},
							onChange(page) {
								setPage(page);
							},
						}}
						rowKey={(record) => {
							return record.fundId;
						}}
					/>
					<ReturnCurveModal fundId={fundId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
				</>
			)}
		</div>
	);
}
