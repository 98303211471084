export const getInit = (
	hasReferenceStrategies: boolean,
	hasReferenceInvestmentReport: boolean,
	referenceStrategiesInit: boolean,
	referenceInvReportInit: boolean
) => {
	if (hasReferenceStrategies && hasReferenceInvestmentReport) {
		return referenceStrategiesInit && referenceInvReportInit;
	} else {
		return referenceStrategiesInit || referenceInvReportInit;
	}
};
