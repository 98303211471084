/**
 * 枚举定义工具
 * 示例：
 * const STATUS = createEnum({
 *     AUDIT_WAIT: [1, '审核中'],
 *     AUDIT_PASS: [2, '审核通过']
 * })
 * 获取枚举值：STATUS.AUDIT_WAIT
 * 获取枚举描述：STATUS.getDesc('AUDIT_WAIT')
 * 通过枚举值获取描述：STATUS.getDescFromValue(STATUS.WAIT)
 *
 */

interface EnumDefinition {
	[enumName: string]: [string, string];
}

export default function createEnum(definition: EnumDefinition) {
	const strToValueMap: { [key: string]: string } = {};
	const strToDescMap: { [key: string]: string } = {};
	for (const enumName of Object.keys(definition)) {
		const [value, desc]: [string, string] = definition[enumName];
		strToValueMap[enumName] = value;
		strToDescMap[value] = desc;
	}
	return {
		...strToValueMap,
		getDesc(enumName: string): string {
			return (definition[enumName] && definition[enumName][1]) || "";
		},
		getDescFromValue(value: string): string {
			return strToDescMap[value] || "";
		},
	};
}

export enum Exchange {
	Binance = "BINANCE",
	KuCoin = "KUCOIN",
}

export enum ExchangeType {
	SPOT = "SPOT",
	PERPETUAL = "PERPETUAL",
}

export interface Pair {
	id: number;
	symbol: string;
	type: ExchangeType;
	pricePrecision: number;
	filters: string;
	baseToken: string;
	quoteToken: string;
	exchange: Exchange;
	minPrice: number;
	maxPrice: number;
	firstTradingTime: number;
	tickSize: number;
	minQty: number;
	maxQty: number;
	stepSize: number;
	notional: number;
}

export enum RangeType {
	PAIR = "pair",
	MIN = "min",
	MAX = "max",
}

export enum StatusType {
	Normal = "",
	Error = "error",
}