import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { zh_CN } from "./languages/zh_CN";
import { zh_HK } from "./languages/zh_HK";
import { en } from "./languages/en";

const resources = {
	en: {
		translation: en,
	},
	zh_CN: {
		translation: zh_CN,
	},
	zh_HK: {
		translation: zh_HK,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
