import React from "react";
import { DatePicker, Form, Select } from "antd";
import styles from "./ImportInvestmentReportForm.less";
import dateIcon from "src/shared/assets/png/dateIcon.png";
import { ValidateStatus } from "antd/es/form/FormItem";
import useLanguage from "@shared/hooks/useLanguage";
import { AllValues } from "@/manager/typings/importStrategies";
import { ReferenceInvestmentReport, ReportSummary } from "@/manager/typings/importInvestmentReport";
import { Key } from "antd/es/table/interface";
import { Accounts } from "@/manager/typings/accounts";
import "src/shared/config/dayjsConfig";
import locale from "antd/es/date-picker/locale/en_US";
import {
	useComposeInvestmentReportParams,
	useSubmit,
	useUpdateAccounts,
	useUpdateInvestmentReport,
} from "@/manager/hooks/investmentFormHooks";
import { useFormConfig } from "@/manager/utils/formConfigManagement";

interface Props {
	referenceStrategies: Key[];
	setHasReferenceStrategies: (val: boolean) => void;
	fundAccount: string;
	setHasReferenceInvestmentReport: (val: boolean) => void;
	validate: boolean;
	setValidate: (val: boolean) => void;
	setReferenceInvReportInit: (val: boolean) => void;
	invReportSummary: ReportSummary | undefined;
	setInvReportSummary: (val: ReportSummary) => void;
	setPeriodAccumulativeReturn: (val: (string | number)[][]) => void;
	setReferenceInvestmentReport: (val: ReferenceInvestmentReport) => void;
	submit: boolean;
	setSubmit: (val: boolean) => void;
}

export default function ImportInvestmentReportForm({
	referenceStrategies,
	setHasReferenceStrategies,
	fundAccount,
	setHasReferenceInvestmentReport,
	submit,
	setSubmit,
	validate,
	setValidate,
	setReferenceInvReportInit,
	invReportSummary,
	setInvReportSummary,
	setPeriodAccumulativeReturn,
	setReferenceInvestmentReport,
}: Props) {
	const { t, keys } = useLanguage();
	const [form] = Form.useForm();
	const [accounts, setAccounts] = React.useState<Accounts[]>([]);
	const [start, setStart] = React.useState<number>(0);
	const [end, setEnd] = React.useState<number>(0);
	const [startValidateStatus, setStartValidateStatus] = React.useState<ValidateStatus>("");
	const [endValidateStatus, setEndValidateStatus] = React.useState<ValidateStatus>("");
	const [valueChange, setValueChange] = React.useState<boolean>(false);

	useUpdateAccounts(setAccounts);
	useUpdateInvestmentReport(
		start,
		end,
		fundAccount,
		valueChange,
		setValueChange,
		setInvReportSummary,
		setPeriodAccumulativeReturn
	);

	useSubmit(
		submit,
		setSubmit,
		fundAccount,
		start,
		end,
		referenceStrategies,
		setHasReferenceStrategies,
		setHasReferenceInvestmentReport,
		setValidate,
		startValidateStatus,
		endValidateStatus
	);

	useComposeInvestmentReportParams(
		validate,
		setReferenceInvReportInit,
		fundAccount,
		start,
		end,
		invReportSummary,
		setReferenceInvestmentReport
	);

	const { options, disabledDate } = useFormConfig(fundAccount, accounts);

	return (
		<div className={styles["form-container"]}>
			{accounts.length > 0 && (
				<>
					<div className={styles["account-label"]}>{t(keys.account)}</div>
					<Select
						popupClassName={styles["select-popup"]}
						options={options}
						defaultValue={fundAccount}
						disabled
					/>
					<Form
						layout="vertical"
						form={form}
						onValuesChange={(changedValues, allValues: AllValues) => {
							setValueChange(true);
							changedValues.hasOwnProperty("start") && setStart(changedValues.start?.valueOf());
							changedValues.hasOwnProperty("end") && setEnd(changedValues.end?.valueOf());
						}}>
						<div className={styles["date-range"]}>
							<Form.Item label={t(keys.dateRange)} name="start" validateStatus={startValidateStatus}>
								<DatePicker
									suffixIcon={null}
									placeholder={t(keys.startDate)}
									allowClear={true}
									locale={locale}
									disabledDate={disabledDate}
									onChange={(val: any) => {
										val ? setStartValidateStatus("success") : setStartValidateStatus("error");
									}}
								/>
							</Form.Item>
							<img src={dateIcon} className={styles["start-img"]} />
							<span className={styles["link-word"]}>to</span>
							<Form.Item name="end" validateStatus={endValidateStatus} className={styles["end-time"]}>
								<DatePicker
									suffixIcon={null}
									placeholder={t(keys.finishDate)}
									allowClear={true}
									disabledDate={disabledDate}
									defaultValue={undefined}
									onChange={(val: any) => {
										val ? setEndValidateStatus("success") : setEndValidateStatus("error");
									}}
								/>
							</Form.Item>
							<img src={dateIcon} className={styles["end-img"]} />
						</div>
					</Form>
				</>
			)}
		</div>
	);
}
