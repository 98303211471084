/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Login.less";
import { t } from "i18next";
import { keys } from "@shared/i18next/languages/keys";
import useLanguage from "@shared/hooks/useLanguage";
import { Button, Form, Input } from "antd";
import visibleIcon from "src/shared/assets/png/visibleIcon.png";
import invisibleIcon from "src/shared/assets/png/invisibleIcon.png";
import { useNavigate } from "react-router-dom";
import { parseUserInfo } from "@/login/model/userParser";
import { saveToLocalStorage, tryLogin } from "@/login/model/authService";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface Props {
	setHasAccount(hasAccount: boolean): void;
	setIsResetingPwd(isResetingPwd: boolean): void;
}

export default function LoginEntrance(props: Props) {

	const { t, keys } = useLanguage();
	const [visitorId, setVisitorId] = useState<string>("");

	const [hasAccount, setHasAccount] = useState(true);
	const [isResetingPwd, setIsResetingPwd] = useState(false);
	const [loading, setLoading] = React.useState(false);
	const [accountHelp, setAccountHelp] = React.useState<string>();
	const [userName, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const navigate = useNavigate();

	const inputUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserName(e.target.value);
	};

	const inputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const login = async () => {
		setLoading(true);
		if (userName === "" || password === "") {
			setAccountHelp(t(keys.incorrectAccountPasswordPleaseTryAgain));
		} else {
			const result = (await tryLogin(userName, password, visitorId))?.data;
			if (result?.code === "OK") {
				const userInfo = parseUserInfo(result);
				await saveToLocalStorage(userInfo);

				setAccountHelp("");
				navigate("/fundManagement/myfund");
			} else {
				setAccountHelp(t(keys.incorrectAccountPasswordPleaseTryAgain));
			}
		}
		setLoading(false);
	};

	const switchLoginInterface = () => {
		setIsResetingPwd(true);
		setHasAccount(!hasAccount);
	};

	useEffect(() => {
		FingerprintJS.load()
			.then((fp) => fp.get())
			.then((result) => {
				setVisitorId(result.visitorId);
			});
	}, []);

	useEffect(() => {
		setAccountHelp("");
	}, [userName, password]);

	useEffect(() => {
		props.setHasAccount(hasAccount);
	}, [hasAccount]);

	useEffect(() => {
		props.setIsResetingPwd(isResetingPwd);
	}, [isResetingPwd]);

	return (
		<>
			<div className={styles["header"]}>
				<div>{t(keys.logIn)}</div>
			</div>
			<div className={styles["user-information"]}>
				<Form>
					<Form.Item label={accountLabel()} colon={false}>
						<Input
							status={accountHelp === "" ? "" : "error"}
							onChange={inputUserName}
							data-testid="username"
							placeholder={t(keys.pleaseInput)}
						/>
					</Form.Item>
					<Form.Item label={passwordLabel()} help={accountHelp} colon={false}>
						<Input.Password
							status={accountHelp === "" ? "" : "error"}
							onChange={inputPassword}
							data-testid="password"
							placeholder={t(keys.pleaseInput)}
							iconRender={(visible) =>
								<img src={visible ? visibleIcon : invisibleIcon} style={{ width: "16px", height: "16px" }} />
							}
						/>
					</Form.Item>
					<div className={styles["forgetPassword"]} onClick={switchLoginInterface}>{t(keys.passwordForgotten)}</div>
				</Form>
			</div>
			<div className={styles["button"]}>
				<Button
					className={styles["application-btn"]}
					onClick={login}
					loading={loading}
				>
					{t(keys.logIn)}
				</Button>
			</div>
		</>
	);
}

const accountLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.user)}:</span>
		</div>
	);
};

const passwordLabel = () => {
	return (
		<div className={styles["label"]}>
			<span className={styles["labelContent"]}>{t(keys.password)}:</span>
		</div>
	);
};