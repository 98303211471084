import moment from "moment";
import { ReportSummary } from "../typings/importInvestmentReport";

export const genPeriodAccumulativeReturn = (reportSummary: ReportSummary): (string | number)[][] => {
	return reportSummary.reports.map((report) => [
		moment(report.day).format("YYYY-MM-DD"),
		(report.accumulativeReturn * 100).toFixed(2),
	]);
};

export const getOption = (periodAccumulativeReturn: (string | number)[][]) => {
	return {
		xAxis: {
			type: "category",
			show: true,
			splitLine: {
				type: "dashed",
			},
		},
		yAxis: {
			type: "value",
			show: true,
			axisLabel: {
				formatter: "{value} %",
			},
		},
		grid: {
			top: "5%",
			left: "10%",
			right: "5%",
			bottom: "10%",
		},
		tooltip: {
			trigger: "axis",
		},
		color: {
			type: "linear",
			x: 0,
			y: 0,
			x2: 0,
			y2: 1,
			colorStops: [
				{
					offset: 0,
					color: "#455CDC99",
				},
				{
					offset: 0.9,
					color: "#FFFFFF",
				},
			],
			global: false,
		},
		series: [
			{
				data: periodAccumulativeReturn,
				type: "line",
				lineStyle: {
					color: "#455CDC99",
					width: 2,
				},
				areaStyle: {},
				symbol: "none",
				smooth: true,
			},
		],
	};
};
