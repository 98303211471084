import React from "react";
import { Button } from "antd";
import styles from "./StepsButtons.less";
import useLanguage from "@shared/hooks/useLanguage";
import { Step } from "@/manager/typings/steps";
import _ from "lodash";
import { FundFactorParams } from "@/manager/typings/fundFactor";
import { canFundFactorParamsSunbmit } from "@/manager/service/createFund";

interface Props {
	current: number;
	setCurrent: (current: number) => void;
	steps: Step[];
	setSubmit: (val: boolean) => void;
	fundFactorParams: FundFactorParams;
	setNeedCheckFrom: () => void;
}
export default function StepsButtons({
	current,
	setCurrent,
	steps,
	setSubmit,
	fundFactorParams,
	setNeedCheckFrom,
}: Props) {
	const { t, keys } = useLanguage();

	return (
		<div className={styles["steps-buttons"]}>
			{current < steps.length - 1 && (
				<Button
					type="primary"
					onClick={() =>
						canFundFactorParamsSunbmit(fundFactorParams) ? setCurrent(current + 1) : setNeedCheckFrom()
					}>
					{t(keys.next)}
				</Button>
			)}
			{current === steps.length - 1 && (
				<Button
					type="primary"
					onClick={_.debounce(() => {
						setSubmit(true);
					}, 1000)}>
					{t(keys.publish)}
				</Button>
			)}
			{current > 0 && (
				<Button style={{ margin: "0 8px" }} onClick={() => setCurrent(current - 1)}>
					{t(keys.previous)}
				</Button>
			)}
		</div>
	);
}
