import React from "react";
import ReactEcharts from "echarts-for-react";
import styles from "./AccumulativeChart.less";
import emptyImg from "src/shared/assets/png/emptyImg.png";
import useLanguage from "@shared/hooks/useLanguage";
import { getOption } from "@/manager/utils/accumulativeChartGenerator";

interface Props {
	periodAccumulativeReturn: (string | number)[][];
}
export default function AccumulativeChart({ periodAccumulativeReturn }: Props) {
	const { t, keys } = useLanguage();
	const option = getOption(periodAccumulativeReturn);

	return (
		<div className={styles["chart-container"]}>
			<div className={styles["chart-header"]}>{t(keys.cumulativeReturn)}</div>
			{periodAccumulativeReturn.length > 0 ? (
				<>
					<div className={styles["chart"]}>
						<ReactEcharts option={option} style={{ width: "100%", height: "100%" }} />
					</div>
				</>
			) : (
				<img src={emptyImg} className={styles["empty-img"]} />
			)}
		</div>
	);
}

