
import { urls } from "@shared/config/envconfig";
import { postRequest } from "@shared/utils/postRequests";
import { getFundamentalRequest } from "@shared/utils/request";

export async function isMailExisted(mail: string) {

	return await getFundamentalRequest(`/public/register/isMailExisted/${mail}`, {});
}

export async function getVerificationCode(mail: string) {

	return await postRequest(urls.fundamental, `/public/register/sendVerificationCode?mail=${mail}`, {});
}

export async function createAccount(
	mail: string,
	password: string,
	verificationCode: string,
) {
	return postRequest(urls.fundamental, "/public/register/registerCis", { mail, password, verificationCode });
}

export async function resetPwd(
	mail: string,
	password: string,
	verificationCode: string,
) {
	return postRequest(urls.fundamental, "/public/register/modifyPassword", { mail, password, verificationCode });
}