import { Key } from "antd/es/table/interface";
import { FundFactorParams } from "../typings/fundFactor";
import { ReferenceInvestmentReport } from "../typings/importInvestmentReport";

export const composeNewFund = (
	fundFactorParams: FundFactorParams,
	referenceStrategies: Key[],
	referenceInvestmentReport: ReferenceInvestmentReport | undefined
) => {
	return {
		...fundFactorParams.baseInformation,
		// Type would be always 'OPEN' this version.
		type: "OPEN",
		fundStrategies: referenceStrategies,
		elements: {
			...fundFactorParams.benefitAndRiskFactors,
			...fundFactorParams.rangeOfTradingTargets,
		},
		investmentReport: referenceInvestmentReport
			? {
					...referenceInvestmentReport,
			  }
			: undefined,
	};
};
