import { keys } from "@shared/i18next/languages/keys";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default () => {
	const { t, i18n } = useTranslation();

	const changeLanguage = useCallback(
		(lang: string) => {
			i18n.changeLanguage(lang);
			window.localStorage.setItem("language", lang);
		},
		[i18n]
	);

	const initLang = useCallback(() => {
		const lang = window.localStorage.getItem("language") || "en";

		changeLanguage(lang);
	}, [changeLanguage]);

	return {
		currentLang: i18n.language,
		keys,
		t,
		initLang,
		changeLanguage,
	};
};
